a.nav-link {
    display: flex;
    /* flex-direction: column;
    justify-content: center; */
    align-items: center;
    padding: 0 3px;
    margin: 0 12px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    transition: .4s;
    color: #3e6493;
}

.user-avatar {
    margin: 0px 15px;
    cursor: pointer;
}

.navbar-light .navbar-brand {
    cursor: pointer;
}

a#toggleBTN {
    margin-left: 10px;
}

.user-img img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 10px;
    object-fit: cover;
}

.dash-head ul {
    align-items: center;
}