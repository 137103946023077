/* hiring section css*/
section.hiring-talent {
    padding-top: 50px;
}
.hiring-bg {
    background: #FDFAF6;
    padding: 30px 20px 30px;
    border-radius: 12px;
}
.box-two {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 12px;
    cursor: pointer;
    padding: 16px;
    margin: 10px;
    box-shadow: 0 -1px 14px #27497d0f;
    width: 100%;
    transition: .3s;
}

.box-two:hover{
    transform: scale(1.05)
}

.box-one:hover{
    transform: scale(1.05)
}

.jobs-box {
    padding-left: 12px;
}

.jobs-box h3 {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0;
    color: #1c4980;
}
.jobs-box p {
    display: inline-flex;
    align-items: center;
    color: #0073e6;
    font-size: 12px;
    font-weight: 400;
    margin-top: 8px;
    line-height: 100%;
    text-decoration: none;
}
span.text-two {
    color: #e67519;
}
/*end*/

/* engaging section*/
section.opportunities {
    padding-top: 50px;
}
.row.opp-bg {
    background: #F5FAFF;
    padding: 30px 20px 30px;
    border-radius: 12px;
}
.main-head h2 {
    margin-bottom: 8px;
    font-size: 20px;
    color: #1c4980;
    font-weight: 500;
}
span.text {
    color: #0073e6;
}
 .box-one {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 12px;
    cursor: pointer;
    padding: 16px;
    margin: 10px;
    box-shadow: 0 -1px 14px #27497d0f;
    width: 100%;
    transition: .3s;
}
.general-box h3 {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0;
    color: #1c4980;
}
.general-box {
    padding-left: 12px;
}
.general-box p {
    display: inline-flex;
    align-items: center;
    color: #0073e6;
    font-size: 12px;
    font-weight: 400;
    margin-top: 8px;
    line-height: 100%;
    text-decoration: none;
}
.arrow-icon i.fa.fa-arrow-right {
    margin-left: 6px;
    font-size: 14px;
    transition: .3s;
    font-size: 12px;
}
/* engaging section end */

/*navbar*/

.new {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    box-shadow: 1px 1px 5px #0073e6;
    z-index: 10;
  }