  .user-sidebar {
    overflow-y: scroll;
  }

  .user-sidebar{
    overflow-x: hidden;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }


  ::-webkit-scrollbar-track {
    background-color: transparent;
  }


  ::-webkit-scrollbar-thumb {
    background-color: #0073e6;
    border-radius: 5px;
  }


  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

.menu_list {
  margin: 18px 0px;
}

.user-detail h6 {
  font-size: 14px;
  line-height: 120%;
  font-weight: 600;
  color: #383838;
  margin: 0;
}

.user-detail p {
  margin: 0;
  color: #383838;
  font-size: 12px;
  line-height: 15px;
}

.menu_list .complete-btn {
  background: #fff;
  border-radius: 12px;
  padding: 10px 15px;
  color: #383838;
  font-size: 14px;
  display: block;
  margin-top: 15px;
  width: 100%;
  transition: .3s;
  border: 1px solid #afafaf;
}

.menu_list ul {
  margin: 10px 0px;
}
.menu_list li.active {
  padding: 10px 24px !important;
}

.menu_list ul li {
  padding: 10px 24px;
}

.menu_list ul li a {
  padding: 10px 0px;
  width: 100%;
  align-items: center;
  transition: .4s;
  color: #383838;
  font-size: 14px;
}

.menu_list p {
  border-top: 1px dashed #dadce0;
  font-size: 12px;
  font-weight: 400;
  color: #383838;
  padding-top: 16px;
}

.menu_list ul li a span {
  margin-right: 12px;
}