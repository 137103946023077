@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,800;1,900&display=swap');
@import url('./../assets/fonts/stylesheet.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  scroll-behavior: smooth;
}

.alert-info-bg {
  color: #856404;
  background-color: #f8eed4;
  border: 1px solid #ffeeba;
  padding: 12px 20px 12px 12px!important;
  border-radius: .25rem;
  text-align: center;
  position: relative;
  justify-content: center;
}

body {
  overflow-x: hidden;
  font-family: 'MADE TOMMY';
} 

main {
   overflow-x: hidden; 
  
}

a {
  text-decoration: none;
}

.btn:hover {
  color: #ffffff;
}

figure {
  margin: 0;
  padding: 0;
}

.btn:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

button:focus {
  outline: none;
  box-shadow: none;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}


.slick-dots li button:before {
  display: none;
}

/* header started  */

header .logo {
  width: 100% !important;
  height: 60px;
  position: relative;
  top: 0;
}

.navbar-light .navbar-brand {
  color: rgb(255 255 255 / 100%);
  background: #0073E6;
  margin: 0;
  padding: 5px 14px 6px 15px;
  border-radius: 6px;
  font-size: 25px;
}

button.btn {
  background: #0073e6;
  color: #fff;
  padding: 9px;
  font-family: 'MADE TOMMY';
  width: 120px;
  margin: 0 0 0 15px;
  font-size: 18px;
  border-radius: 10px;
  outline: 0;
  border: 2px solid #0073e6  !important;
}

button.btn:hover {
  background: transparent;
  color: #0073e6;
}

button.btn2:hover {
  background: transparent !important;
  border: 2px solid #ff7b00 !important;
  color: #ff7b00 !important;
}

button.btn2 {
  background: #ff7a00;
  border: 2px solid #ff7a00 !important;
}

header .width {
  width: 300px;
}

header {
  position: relative;
  padding-top: 25px;
  height: 95px;
}

header::before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  right: 0;
  height: 15px;
  background: url('./../assets/images/head.png');
  background-position: center;
  background-size: 100% 100%;
  filter: brightness(0) saturate(100%) invert(51%) sepia(73%) saturate(2438%) hue-rotate(360deg) brightness(101%) contrast(106%);
}

header nav {
  background-color: unset;
  top: -14px;
}

/************************** HomeBanner **************************/
.banner {
  padding: 0px 0 20px 0;
  background: #f0f0f07a;
}

.banner h6 {
  font-size: 29px;
  color: #969696;
  font-weight: 500;
  letter-spacing: 2px;
  font-family: 'MADE TOMMY';
  text-align: center;
  text-transform: uppercase;
}

.banner h5 {
  position: relative;
  font-family: MADE TOMMY;
  font-size: 77px;
  font-weight: 400;
  line-height: 90px;
  letter-spacing: -0.035em;
  text-align: center;
  text-transform: capitalize;
  margin: 0px 0 33px 0;
}

.banner h5 span {
  /* font-family: Playfair Display;
  font-style: italic;
  font-weight: 600; */
  color: #0073E6;
}

/* .banner .img-div {
   margin-top: 30px; 
} */

.art {
  position: absolute;
  top: 117px;
}

.cl {
  position: absolute;
  right: 0;
}

.sl {
  margin-top: 65px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/************************** HomeBanner **************************/

/************************** Mega **************************/
.cls {
  display: flex;
  padding: 14px 7px 9px 0;
  box-shadow: #107cc647 0px 2px 8px 0px;
  border-radius: 4px;
  margin-top: 25px;
  background: #fff;
}

.cls .cl2,
.cls .cl3,
.cls .cl5 {
  width: 25%;
}

.cls .cl1,
.cls .cl4 {
  width: 25%;
}

ul.brr {
  border-right: 1px solid #8b8b8b;
  padding-left: 6px;
}

ul.brr a {
  font-family: 'MADE TOMMY';
  font-size: 18px;
  color: #000;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

ul.brr li {
  margin: 10px;
}

ul.brr li img {
  margin-right: 10px;
}



/************************** Mega **************************/
.mega {
  margin-top: -590px;
  transition: 1s;
  z-index: 999;
  position: relative;
}

.megaShow {
  margin-top: unset !important;
  margin-top: -43px;
}

/************************** HomeInd **************************/
.ind {
  background: #f0f0f0;
  padding-top: 125px;
}

.ind h4 {

  font-family: MADE TOMMY;

  font-size: 35px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
}

.ind h4 span {
  text-transform: capitalize;
  color: #0073E6;
  font-weight: 400;
}

.comp .slick-slide img {
  display: block;
  width: 105px;
}

.comp .slick-track {
  display: flex;
  align-items: center;
}

.ind .main-div {
  /* border-bottom: 2px solid #455A64;
  border-top: 2px solid #455A64; */
  padding: 15px 0;
  margin-top: 42px;
  background: #f7b90317;
  margin-bottom: 40px;
}
/* .indus-vet {
  border-bottom: 2px solid #455A64;
  border-top: 2px solid #455A64;
  padding: 15px 0;
   margin-top: 42px;
  background: #f7b90317;
} */

/************************** HomeInd **************************/

/************************** HomeExplore **************************/
.ex {
  position: relative;
  background: #f0f0f0;
  z-index: 1;
  padding: 75px 0;
}

.ex::before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url('./../assets/images/exbg.png');
  z-index: -1;
  opacity: .1;
}

.conn p {
  font-family: MADE TOMMY;
  font-size: 18px;
  font-weight: 400;
  margin: 12px 0 28px;
  /* text-transform: capitalize; */
  color: #3B4045;
}

.conn h3 {
  font-family: MADE TOMMY;
  font-size: 45px;
  font-weight: 400;
  text-transform: capitalize;
}

/* .conn h4 {
  font-family: MADE TOMMY;
  font-size: 45px;
  font-weight: 400;
  /* text-transform: capitalize; 
} */

.conn h3 span {
  color: #fdb902;
}

.conn button.btn {
  background: #0073E6;
  padding: 11px;
  width: 238px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 9px;
  border: 2px solid #0073E6 !important;
  color: #fff;
  transition: .5s;
}

.conn button.btn:hover {
  background: transparent;
  color: #0073E6;
}

.ex .con {
  height: 100%;
  display: flex;
  align-items: center;
}

.ex .slick-dots li,
.op .slick-dots li,
.bn-slider1 .slick-dots li,
.tab-sli .slick-dots li {
  position: relative;
  display: inline-block;
  width: unset;
  height: unset;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  background: transparent;
}

.ex .slick-dots li button,
.op .slick-dots li button,
.tab-sli .slick-dots li button {
  font-size: 0;
  line-height: 0;
  width: 20px;
  height: 6px;
  padding: 0;
  color: transparent;
  border: 0;
  border-radius: 9px;
  outline: none;
  background: #0073e6;
}

.ex li.slick-active,
.op li.slick-active,
.bn-slider1 li.slick-active,
.tab-sli li.slick-active {
  margin: 15px;
}

.ex li.slick-active button,
.op li.slick-active button,
.bn-slider1 li.slick-active button,
.tab-sli li.slick-active button {
  width: 38px !important;
}

.bn-slider1 li.slick-active button {
  width: 410px !important;
}

.bn-slider1 li.slick-active {
  margin: 7px;
}


.op .box {
  position: relative;
  cursor: pointer;
  margin: 60px 5px 0;
}

.op .content {
  display: flex;
}

.op .box img {
  height: 100%;
  border-radius: 38px;
  object-fit: cover;
  box-shadow: rgb(99 99 99 / 9%) 0px 2px 17px 0px;
}

/* //hover // */
.op .box .txt0 {
  display: none;
}

.op .box::before {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: 0.5s;
  border-radius: 32px;
}

.op .box:hover:before {
  background-color: rgb(0 0 0 / 70%);
}

.slideiu li:nth-child(even) {
  position: relative;
  bottom: -85px;
}

.slideiu {
  display: flex;
  justify-content: space-around;
  margin-bottom: 145px;
  margin-top: 45px !important;
}

li.lili {
  width: 17.5%;
  height: 100%;

}

.lili img {
  cursor: pointer;
  background: #fff;
}

.op .box .txt1 span {
  height: 100px;
  width: 100px;
  border: 5px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 20px;
  margin: 0 10px 0 0;
  cursor: pointer;
  color: #fff;
  font-size: 39px;
}

.op .box .txt1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.op .box:hover .txt0 {
  display: block;
}

.mobile-tabs{
  display: none;
}

.op .p {
  font-family: MADE TOMMY;
  font-size: 20px;
  color: #fff;
  margin-top: 6px;
}

.op h4 {
  font-family: MADE TOMMY;
  font-size: 22px;
  font-weight: 500;
  text-transform: capitalize;
  color: #FD9801;
}

.op {
  background: #f0f0f0;
  padding: 0 0 100px 0;
  position: relative;
}

.pos-left {
  position: relative;
  left: -60px;
  animation: horizontal-transform 1s linear infinite;
}

.pos-right {
  position: relative;
  left: 60px;
  animation: horizontal-transform2 1s linear infinite;
}

.hi::before {
  background: url('./../assets/images/hi.png');
  opacity: .6;
  width: 50%;
  left: unset;
}

.hi {
  background-color: unset;
  background-image: url('./../assets/images/hi2.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background: #e1effe;
}

.s-div {
  position: relative;
  height: 300px;
  display: flex;
  align-items: center;
}

.s-div:hover .s1 {
  left: -20px !important;
  width: 32% !important;
}

.s-div .s1 {
  transition: .5s;
  position: absolute;
  left: 15px;
  width: 27%;
}

.s-div .s2 {
  position: absolute;
  left: 50%;
  z-index: 1;
  width: 45%;
  bottom: 1px;
  transform: translateX(-50%);
  top: 48px;
}

.s-div:hover .s3 {
  right: -20px !important;
  width: 32% !important;
}

.s-div .s3 {
  position: absolute;
  transition: .5s;
  right: 15px;
  width: 30%;
}

.featured-slider .slick-slide img {
  transition: .5s;
}

.featured-slider .slick-slide img:hover {
  animation: tilt-shaking 1s linear infinite;
}



.s-all h2 {
  font-family: MADE TOMMY;
  font-size: 35px;
  font-weight: 400;
  text-transform: capitalize;
  color: #0073e6;
  text-align: center;
  margin-top: 0px;
}

.s-all {
  background: #f0f0f0;
  padding: 100px 0 80px 0;
}

.op .tx {
  display: flex;
}

.op .tx h3 {
  font-family: MADE TOMMY;
  font-size: 14px;
  font-weight: 400;
  margin: 0px 20px 15px 0;
  text-transform: capitalize;
  color: #0d6efd;
  display: flex;
  align-items: center;
  background: #6bb1f980;
  padding: 4px 10px 4px 4px;
  border-radius: 6px;
}

.op .tx h3 span {
  background: #0d6efd;
  margin-right: 6px;
  width: 33px;
  padding: 7px;
  padding: 6px;
  color: #fff;
  padding: 7px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  width: 34px;
  margin-right: 10px;
  font-size: 13px;
  margin-right: 5px;
}

.bx {
  padding: 0 49px;
  position: relative;
  top: -22px;
}

.op hr {
  height: 2px;
  background: #969696;
  opacity: 1;
}

.op .slick-dots {
  bottom: -105px;
}

.prac {
  color: #feb902 !important;
}

.men {
  color: #9c69a1 !important;
}

.bckk {
  position: relative;
  z-index: 1;
}

.bckk::before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url('./../assets/images/exbg.png');
  z-index: -1;
  opacity: .1;
}

.bckkk {
  position: relative;
  z-index: 1;
  height: 100%;
}

.bckkk::before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-image: url('./../assets/images/bannernew.png') !important;
  z-index: -1;
  opacity: 1;
  animation: horizontal-shaking 1.5s linear infinite;
}

/************************** HomeExplore **************************/
.progress-wrapper {
  max-width: 350px;
  justify-content: space-between;
  margin: auto;
  padding: 20px;
  text-transform: uppercase;
}

[type="range"] {
  width: 200px;
}

.section {
  position: relative;
  border-radius: 7px;
  margin: 20px;
  width: 300px;
  left: 30px;
}

.section .pie {
  width: 300px !important;
  height: 300px !important;
  position: relative;
}

.section svg {
  height: 300px !important;
  width: 300px !important;
}

text.pie-text-1 {
  display: none;
}

.ele h3 {

  font-family: MADE TOMMY;

  font-size: 40px;

  font-weight: 500;

  text-transform: capitalize;

  color: #0073e6;
}

.ele p {

  font-family: MADE TOMMY;

  font-size: 15px;

  font-weight: 500;

  text-transform: capitalize;

  color: #05254D;
}

.ele {

  position: absolute;

  z-index: 1;

  top: 50%;

  left: 50%;

  transform: translate(-50%, -50%);

  text-align: center;

  line-height: 14px;
}

.pie text {
  opacity: 0;
}

.numbers-slider .slick-slide .div {
  display: flex !important;
  justify-content: center;
}

.numbers-slider .slick-dots {
  bottom: -73px;
}

/*----------------- news -----------------------*/
.news {
  background: #0073e6;
  position: relative;
  /* z-index: -1; */
  padding: 58px 0;
}

.news::before {
  position: absolute;
  content: '';
  left: 0%;
  top: -95px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('./../assets/images/up2.png');
  width: 129px;
  height: 150px;
}

footer .bg-img::after {
  position: absolute;
  content: '';
  left: 2%;
  bottom: 0px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('./../assets/images/up2.png');
  width: 105px;
  height: 145px;
}



.news .conn h3 {
  font-family: MADE TOMMY;
  font-size: 40px;
  font-weight: 400;
  text-transform: capitalize;
  color: #fff;
}

.news .conn p {
  font-family: MADE TOMMY;
  font-size: 18px;
  font-weight: 400;
  margin: 12px 0 28px;
  /* text-transform: capitalize; */
  color: #ffffff;
  opacity: 1;
}

.inp1 {
  padding: 9px 20px;
  background: #489df3;
  border-radius: 12px;
  outline: 0;
  border: 0;
  width: 100%;
  color: #FFF;
  font-family: MADE TOMMY;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  height: 56px;
}

.inp1::placeholder {
  color: #FFF;
}

.inp {
  position: relative;
  margin-bottom: 20px;
}

.inp button {
  width: 61px;
  border-radius: 8px;
  background: #0073e6;
  color: #ffff;
  font-size: 24px;
  padding: 0 18px;
  position: absolute;
  right: 10px;
  border: 0;
  top: 10px;
  bottom: 10px;
}

.news ul {
  display: flex;
  justify-content: space-between;
}

.news li {
  width: 49%;
  text-align: left;
  float: left;
  position: relative;
  /* left: -19px; */
}

input.inp2,
.pub {
  height: 48px;
  padding: 20px;
  text-align: end;
  width: 100%;
  border-radius: 10px;
  outline: 0;
  border: 0;
  font-family: MADE TOMMY;
  margin-right: 20px;
}

.pub {
  background: #fdb902 !important;
  color: #fff;
  padding: 10px !important;
  width: 100% !important;
  margin: 0;
  font-size: 16px !important;
  text-align: center !important;
  margin-left: 20px !important;
  border: 2px solid #0073e6 !important;
}



/*   
font-family: 'MADE TOMMY';
font-family: 'Playfair Display', serif;
*/
.before {
  position: absolute;
  content: '';
  left: 30%;
  bottom: 0;
  right: 30%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-image: url('./../assets/images/bn-line.png');
  height: 10px;
}

/************************** media-query **************************/
.view1 h3 {
  color: #05254D;
  font-family: MADE TOMMY;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 131.5%;
  letter-spacing: -0.18px;
  text-transform: capitalize;
  width: 80%;
  margin-top: 24px;
}

.view1 h2 {
  color: #05254D;
  font-family: MADE TOMMY;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  margin: 40px 0px 25px 0;
}

.view1 a {
  color: #05254D;
  font-family: MADE TOMMY;
  font-size: 19.017px;
  font-style: normal;
  font-weight: 400;
  line-height: 29.5px;
  letter-spacing: -0.23px;
  /* text-transform: capitalize; */
}

.view1 .timing {
  color: #BABABA;
  font-family: MADE TOMMY;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.5px;
  letter-spacing: -0.16px;
  text-transform: capitalize;
  position: relative;
  left: 45px;
}

.view2 {
  color: #05254D;
  /* Text1 */
  font-family: MADE TOMMY;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 151%;
  /* 24.16px */
  letter-spacing: -0.16px;
  text-transform: capitalize;
  margin-top: 150px;
  position: relative;
  bottom: 20px;
}

.view1 .siri {
  width: 26px;
}

.links h6 {
  color: #05254D;
  font-family: MADE TOMMY;
  font-size: 27.839px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 32.839px */
  letter-spacing: -0.657px;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.links ul li {
  margin: 5px 4px;
}

.links li a {
  color: #05254D;
  font-family: MADE TOMMY;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
}

.links ul {
  padding-top: 35px;
}

footer .bg-img {
  position: relative;
  padding: 0 0 60px 36px;
  height: 500px;
}

footer .bg-img::before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-image: url('./../assets/images/foo.png');
  z-index: -1;
}

.inner1 {
  display: grid;
  justify-content: space-between;
  align-content: space-between;
}

.copi {

  color: #FFF;

  font-family: MADE TOMMY;

  font-size: 18px;

  font-style: normal;

  font-weight: 500;

  line-height: 131.5%;
  /* 23.67px */

  letter-spacing: -0.18px;

  text-transform: capitalize;

  background: #0073e6;

  padding: 17px;

  margin-top: 25px;
}

.links ul li:nth-child(odd) {
  filter: brightness(0) saturate(100%)
}

footer {
  padding-top: 50px;
}

.banner .slider {
  height: 641px;
}

button.btn.pub:hover {
  color: #fff;
  /* background: transparent !important; */
  border: 2px solid #0073E6 !important;
}

footer .container {
  max-width: 1530px;
}

/* Animations */
/* @keyframes horizontal-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
 }

 @keyframes horizontal-transform {
  0% {left: -60px;}
  50% {     left: -30px; }
  75% {     left: -10px;}
  100% {     left: -60px; }
 }
 @keyframes horizontal-transform2 {
  0% {left: 60px;}
  50% {     left: 30px; }
  75% {     left: 10px;}
  100% {     left: 60px; }
 }
 @keyframes tilt-shaking {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(3deg); }
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-3deg); }
  100% { transform: rotate(0deg); }
} */


/* ---------------- Learn ---------------- */

.learnbg {
  /* height: 100vh; */
  z-index: 1;
  position: relative;
}

.learnbg::before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  height: unset;
  background-image: url('./../assets/images/bannernew.png');
  animation: horizontal-shaking 1.5s linear infinite;
}

.learnbg .conn {
  margin-top: 110px;
}

.learnbg .jap {
  position: relative;
  left: -65px;
}

/* .learnbg .img-fluid { */
  /* max-width: 90%; */
/* } */

.learnbg .jap2 {
  position: relative;
  right: -60px;
}

.lebg1 .ind {
  padding-top: 10px;
}

/* ---------------- Learn ---------------- */

/* ---------------- tabbs ---------------- */

.tabbs .nav-tabs .nav-link {
  background: #6bb1f9a3;
  margin: 0 6px;
  border-radius: 6px;
  font-family: MADE TOMMY;
  color: #212529;
  font-size: 14px;
}

.tabbs .nav-tabs .nav-link.active,
.tabbs .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #084298 !important;
  color: #fff !important;
}

.tabbs .nav-tabs {
  border-bottom: 0;
  justify-content: center;
  margin-bottom: 65px;
}

.cer .check {
  height: 55px;
  width: 55px;
  object-fit: cover;
  position: relative;
  bottom: 10px;
}

.tb-crd li {
  font-family: MADE TOMMY;
  color: #212529;
  font-size: 16px;
  margin: 10px 0;
}

.tb-crd ul,
ol {
  list-style: unset;
  margin: 35px 2px 40px 15px;
}

.tb-crd h3 {
  font-family: MADE TOMMY;
  color: #ffc107;
  font-size: 36px;
  font-weight: 500;
}

.tb-crd p {
  font-family: MADE TOMMY;
  color: #212529;
  font-size: 21px;
  margin-bottom: 15px;
}

.tb-crd span {
  font-weight: 600;
}

.tb-crd button.btn {
  background: #ff7a00;
  color: #fff;
  padding: 11px;
  font-family: 'MADE TOMMY';
  width: 90%;
  margin: 0;
  font-size: 18px;
  border-radius: 10px;
  outline: 0;
  border: 2px solid #ff7a00 !important;
}

.tb-crd {
  background: #fff;
  border-radius: 36px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  /* margin: 0 15px; */
  overflow: hidden;
}

.tb-crd .padd-div {
  padding: 0 32px 23px;
}

.tb-crd2 h3 {
  color: #863390;
}

.tb-crd2 button.btn {
  background: #0073e6;
  border: 2px solid #0073e6 !important;
}

.tb-crd3 h3 {
  color: #863390;
}

.tb-crd3 button.btn {
  background: #fdb902;
  border: 2px solid #fdb902 !important;
}

.tb-crd .slick-center .tb-crd {
  box-shadow: rgb(42 42 169 / 19%) 0px 7px 29px 0px !important;
}

/* the slides */
.tab-sli .slick-slide {
  margin: 0 17px;
}

/* the parent */
.tab-sli .slick-list {
  margin: 0 -17px;
}

.tab-sli .slick-dots {
  bottom: -95px;
}

.cer .conn.text-center.mb-5 {
  margin-bottom: 36px !important;
}

.cer {
  position: relative;
  background: #f0f0f0;
  padding: 70px 0 130px;
}

.cer::before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url('./../assets/images/cer.png');
  z-index: -1;
}

section.cer1 {
  background: #6bb1f93d;
}

.cer1::before {
  content: unset;
}

.cer1 .box {
  background-color: #AA88F3;
  padding: 33px 41px 46px;
  border-radius: 30px;
}

.cer1 .txt {
  font-family: MADE TOMMY;
  color: #ffffff;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  width: 94%;
  margin-top: 19px;
}

.expect {
  background: #f0f0f0;
  padding: 50px 0 90px;
}

.cer1 .box1 {
  background-color: #ffc1076e;
}

.cer1 .box1 .txt {
  color: #FD9801;
}

.cer1 .box2 {
  background-color: #38eb81bf;
}

.cer1 .box2 .txt {
  color: green;
}

.cer1 .box3 {
  background-color: #AA88F3;
}

.cer1 .box4 {
  background-color: #AA88F3;
}

.expect .conn {
  margin-top: 100px;
}

.cer2 {
  background: #f0f0f0 !important;
}

.bgnew {
  margin-top: 180px;
  position: relative;
}

.bgnew::before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('./../assets/images/exbg.png');
  width: 100%;
  opacity: .1;
}

.ind .box .conn h3 {
  font-size: 26px;
  font-weight: 500;
  text-transform: capitalize;
  margin-top: 19px;
}

.ind .box .conn p {
  font-size: 18px;
  font-weight: 400;
  margin: 5px 0 2px;
  color: #3B4045;
}

/* ---------------- tabbs ---------------- */


/*   //////////FAQ//////////// */

.faq-sec .content h3 {
  color: #011452;
  font-size: 25px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif !important;
}

.faq-sec .content h2 {
  color: #000000;
  font-size: 38px;
  font-weight: bold;
  padding: 15px 0;
  font-family: 'Poppins', sans-serif !important;
}

.faq-sec .content p {
  color: #505050;
  font-size: 16px;
  /* font-weight: bold; */
  font-family: 'Lato', sans-serif;
  font-size: 17px;
  font-weight: 400;
  color: #000;
}

.faq-sec .accordion .accordion-item {
  margin: 20px 0;
  border: 0 !important;
  font-family: 'Lato', sans-serif;
  border-radius: 3px;
  border-bottom: 1px solid #0073e6 !important;
}

.faq-sec .accordion .accordion-item .collapsed {
  font-size: 19px;
  font-weight: 500;
  color: #000;
  padding: 20px 20px 33px 20px;
  border: 0;
  box-shadow: none;
  background-color: #cbe4fd;
  font-family: MADE TOMMY;
}

.faq-sec .accordion .accordion-item button {
  font-size: 19px;
  font-weight: 600;
  color: #ff7a00;
  padding: 20px 20px;
  border: 0;
  box-shadow: none;
  background-color: #cbe4fd;
  font-family: MADE TOMMY;
}

.faq-sec .accordion .accordion-item .icon-div button span {
  position: relative;
}

.faq-sec .accordion .accordion-item .icon-div i {
  font-size: 20px;
  position: relative;
  top: 2px;
  right: -4px;
}

.faq-sec .accordion .accordion-item .lst-btn {
  font-size: 16px;
  color: #ffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 3px;
  padding: 17px 20px;
  width: 100%;
  background-color: #011452;
  font-family: 'Lato', sans-serif;
  border: 0;
}

section#faq {
  /*padding: 60px 0 40px;*/
}

#faq .accordion-body {
  font-family: MADE TOMMY;
  font-size: 16px;
  font-weight: 400;
  background: #cbe4fd;
  /* text-transform: capitalize; */
  color: #3B4045;
  padding-bottom: 38px;
}

.faqqq {
  position: relative;
  background: #f0f0f0;
  padding: 105px 0 85px;
}

.ex .slick-dots li,
.op .slick-dots li,
.bn-slider1 .slick-dots li {
  position: relative;
  display: inline-block;
  width: unset;
  height: unset;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  background: transparent;
}

.ex .slick-dots li button,
.op .slick-dots li button,
.bn-slider1 .slick-dots li button {
  font-size: 0;
  line-height: 0;
  width: 20px;
  height: 6px;
  padding: 0;
  color: transparent;
  border: 0;
  border-radius: 9px;
  outline: none;
  background: #0073e6;
}

.ex li.slick-active,
.op li.slick-active,
.bn-slider1 li.slick-active {
  margin: 15px;
}

.ex li.slick-active button,
.op li.slick-active button,
.bn-slider1 li.slick-active button {
  width: 38px !important;
}

.bn-slider1 li.slick-active button {
  width: 410px !important;
}

.bn-slider1 li.slick-active {
  margin: 7px;
}

.owl-carousel {
  margin-top: 52px;
}


/* ------ mission -------- */
.mission::before {
  content: unset;
}

.mission {
  background: transparent;
  margin-top: 60px;
}

.mission .bg {
  position: relative;
  padding: 35px 35px 33px 35px;
  height: 400px;
}

.mission .bg::before {
  position: absolute;
  content: '';
  left: 0%;
  bottom: 0;
  right: 0%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: #fdb902;
  top: 0;
  z-index: -1;
}

/* .mission .bg::after {
  position: absolute;
  content: '';
  top: -21px;
  right: -6%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('./../assets/images/recm1.png');
  height: 300px;
  width: 300px;
  z-index: -1;
} */

.mission .conn p {
  font-size: 16px;
  width: 80%;
  position: relative;
  /* padding-bottom: 18px; */
}


.mission .conn h3 {
  font-size: 37px;
  margin-bottom: 20px;
}

.mission .conn p::before {
  position: absolute;
  content: '';
  left: 0%;
  bottom: 0;
  right: 0%;
  background-color: #000000;
  height: 3px;
}



.leftig {
  position: relative;
  left: -60px;
}

.mission::after {
  content: unset;
}

.ind2 {
  padding-top: 22px;
}

.green {
  color: #0073e6 !important;
}

.purple {
  color: #fdb902 !important;
}

.orange {
  color: #ff7a00 !important;
}

.pink {
  color: #FF83A4;
}

.num {
  font-family: MADE TOMMY;
  font-size: 88px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #3AA144;
  font-weight: 700;
  position: absolute;
  left: 29px;
  top: -40px;
}

.val {
  font-family: MADE TOMMY;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 4px;
  color: #05254D;
  text-transform: uppercase;
}

.ind2 .box .conn h3 {
  font-size: 38px;
  font-weight: 400;
  margin-top: 0;
  margin: 13px 0;
}

.ind2 .conn {
  padding: 0 0 0 26px;
}

.ind2 .box {
  position: relative;
}


.boxii h3 {
  font-family: MADE TOMMY;
  font-size: 30px !important;
  font-weight: 400;
  text-transform: capitalize;
}

.boxii p {
  font-family: MADE TOMMY;
  font-size: 15px;
  font-weight: 400;
  margin: 12px 0 28px;
  text-transform: capitalize;
  color: #6e7377;
}

.boxii .conn {
  padding: 0 0 0 0;
  text-align: center;
}

.boxiii {
  display: flex;
  padding: 115px 0 70px 0;
}

.boxiii li {
  width: 20%;
}

.boxii {
  text-align: center;
}

.boxii .img-fluid {
  max-width: 80%;
}

.sky {
  color: #71B7FA !important;
}

.ind2::before {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url('./../assets/images/cerr3.png');
  width: 50%;
  opacity: .1;
  z-index: 1;
  height: 45%;
}

.ind2::after {
  /* position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url('./../assets/images/cerr3.png');
  width: 50%;
  opacity: .1;
  z-index: 1;
  height: 45%; */
}

.platform {
  background: #f0f0f0;
}

.platform h1 {
  font-family: MADE TOMMY;
  font-size: 26px;
  font-weight: 600;
  margin: 25px 0;
}

.platform p {
  font-size: 15px;
  font-weight: 500;
  margin: 5px 0 2px;
  color: #000;
}

.pl {
  margin-top: 115px;
}

.mar-div {
  margin-top: 100px;
}

.kell {
  padding-bottom: 36px;
}

.unstop {
  padding-top: 110px;
}

.shark {
  width: 58%;
  margin-top: 50px;
}

/* ------ mission -------- */


/* ------ Terms condition -------- */
.terms {
  padding: 20px 0 150px 0;
  background: #f0f0f0;
}

.condi::before {
  background-image: url('./../assets/images/condi-bg.png');
  z-index: -2;
}

.terms .conn h3 {
  font-size: 39px;
  color: #3B4045;
  margin-top: 57px;
  margin-bottom: 25px;
}

.terms .conn p {
  font-size: 17px;
  font-weight: 500;
  margin: 0;
}

.terms li {
  font-size: 17px;
  font-weight: 500;
  margin: 0;
}

.terms ul {
  list-style: inside;
}

.condi {
  padding: 85px 0 10px 0;
  background: #f0f0f075;
}

/* ------ Terms condition -------- */


/* ------ FAQ's -------- */
.faqs .nav-tabs .nav-link.active,
.faqs .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #6bb1f95c !important;
  color: #05254d !important;
}

.faqs .nav-tabs .nav-link {
  background: #f0f0f0;
  margin: 0 6px;
  border-radius: 6px;
  font-family: MADE TOMMY;
  color: #212529;
  font-size: 14px;
}

.faqs .nav-tabs {
  border: 1px solid #05254d75 !important;
  border-radius: 8px;
  padding: 5px 0;
  margin-bottom: 55px;
}

.faqs .gen-box {
  border: 1px solid #05254d33;
  border-radius: 12px;
  display: grid;
  place-items: center;
  padding: 29px 20px;
  align-items: center;
}

.faqs .gen-box p {
  padding: 35px 0;
  font-family: MADE TOMMY;
  font-size: 16px;
  font-weight: 500;
}

.faqs .box {
  background: #cfd9e1;
  border-radius: 10px;
  height: 64px;
  width: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faqs .box img {
  width: 57%;
}

.faqs button.btn {
  padding: 7px !important;
  width: 95px !important;
  margin: 0 0 0 0 !important;
}

.faqs {
  padding: 100px 0 102px;
}

.home-ind::before {
  content: unset !important;
}

.about-ind::before {
  content: unset !important;
}

/* ------ FAQ's -------- */

/*--------- Practice -----------*/
.pract h5 {
  margin: 27px 0 15px 0;
}

.pract {
  margin-top: 0 !important;
}

.pract h3 {
  font-size: 30px !important;
  margin-top: 30px !important;
  line-height: 35px;
  margin-bottom: 20px;
}

.connb {
  text-align: left;
  margin-top: 105px;
}

.mock {
  padding: 50px 60px 90px;
}

.mock1 {
  background: #f0f0f0;
  padding-top: 75px;
}

.testimo .img-div img {
  border: 4px solid #0073e7;
  border-radius: 23px;
  padding: 20px;
  width: 100%;
  object-fit: cover;
}

.testimo .para {
  font-family: MADE TOMMY;
  font-size: 17px;
  font-weight: 400;
  text-transform: capitalize;
  color: #ffffff;
  background: #05254d;
  border-radius: 17px;
  padding: 33px 30px;
}

.testimo .name h3 {
  position: relative;
  font-family: MADE TOMMY;
  font-size: 31px;
  font-weight: 600;
  text-transform: capitalize;
  color: #05254d;
  margin-top: 65px;
}

.name.conn::before {
  position: absolute;
  content: '';
  left: 0%;
  bottom: -8px;
  top: -7px;
  background: #05254d;
  width: 5px;
  border-radius: 13px;
}

.testimo .img img {
  width: 124px;
  object-fit: cover;
  position: relative;
  bottom: 30px;
}

.name.conn {
  padding: 0 0 0 20px;
  position: relative;
  top: 5px;
}

.testimo p {
  font-size: 18px;
  line-height: 24px;
}

.testimo .para1 {
  color: #05254d;
  background: #f0f0f0;
  border: 1px solid #05254d;
}

.compss {
  /* display: flex;
  justify-content: center;*/
  background: #D9D9D9;
  border-radius: 20px;
  padding: 22px 18px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.hex {
  background: white;
  height: 98px;
  width: 92px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  margin: 8px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comps h3 {
  font-size: 25px !important;
  margin-top: 0;
  margin: 27px 0 11px 0;
}

.partner::before {
  height: 100vh;
}

.partner .conn p {
  margin: 12px 0 115px 0;
}

.partner {
  padding-bottom: 100px;
}

.li2 {
  text-align: left;
  position: absolute;
  left: 0;
  top: 49px;
  left: 168px;
}


.japi h6 {
  font-size: 35px;
  color: #0073e6;
  font-weight: 600;
  letter-spacing: 2px;
  font-family: 'MADE TOMMY';
  text-align: left;
  text-transform: uppercase;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #05254D;
  letter-spacing: 1px;
}

.japi h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 40px 0 0px 115px;
  text-transform: capitalize;
  color: #3B4045;
}

.jonny .jap2 {
  position: relative;
  right: -60px;
  top: 60px;
}

.sam .slick-dots {
  bottom: -100px;
}

.tab-sliii img {
  width: 100%;
  object-fit: cover;
}

.tab-sliii .tb-crd {
  background: #fff;
  border-radius: 17px;
  position: relative;
}

.tab-sliii .tb-crd h3 {
  font-family: 'MADE TOMMY';
  color: #05254D;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  top: 13px;
  left: 15px;
}

.simplist {
  background: #f0f0f0 !important;
  padding: 85px 0 95px;
}

.mentees .line1 {
  display: flex;
  justify-content: end;
  align-items: center;
  top: -62px;
}

.mentees .line2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mentees .line3 {
  display: flex;
  justify-content: start;
  align-items: center;
  top: 60px;
}

.mentees h2 {
  font-family: MADE TOMMY;
  font-size: 45px;
  font-weight: 600;
  text-transform: capitalize;
}

.mentees h3 {
  font-family: MADE TOMMY;
  font-size: 56px;
  font-weight: 700;
   /* text-transform: lowercase;  */
  color: #0073e6;
  line-height: 9px;
}
.mentees h3 span {
  color: #05254d;
}

.line1 img.img-fluid {
  width: 38%;
}

.line2 img.img-fluid {
  width: 93%;
}

.line3 img.img-fluid {
  width: 82%;
}

.mentees ul,
.bg-sec {
  position: relative;
}

.mentees ul::before {
  position: absolute;
  content: '';
  left: 0%;
  bottom: 0;
  right: 0%;
  background-repeat: no-repeat;
  z-index: -1;
  background-position: center;
  background: #7db5ed;
  border-radius: 115px;
  top: 0px;
  background-size: 100% 100% !important;
}

.mentees .line1::before {
  left: 22%;
}

.mentees .bg-sec::before {
  background: url('./../assets/images/union.png');
  background-color: transparent;
  background-position: center;
  z-index: 0;
  top: 0px;
  background-size: 100% 100% !important;
  position: absolute;
  content: '';
  left: 0%;
  bottom: 0;
  right: 0%;
  background-repeat: no-repeat;
}

.sec-rows {
  /* margin: 40px 0; */
}

.mentees .line3::before {
  right: 22%;
}

.bg-sec {
  padding: 104px 0;
  z-index: 2;
  margin: 100px 0;
}

.margin-right {
  margin-right: 95px;
}

.margin-right {
  margin-left: 65px;
  margin-bottom: 24px;
}

.coniii button.btn {
  background: #212529;
  width: 325px;
  border: 2px solid #212529 !important;
}

.coniii {
  margin-top: 60px;
}

.hello43 .coniii {
  margin-top: 66px;
}

.hello43 h3 {
  font-family: MADE TOMMY;
  font-size: 100px;
  font-weight: 700;
  text-transform: capitalize;
  color: #ffc107;
  line-height: 9px;
}

.hello43 h2 {
  font-family: MADE TOMMY;
  font-size: 70px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.mentees {
  background: #f0f0f0;
  position: relative;
}

.tesimon p {
  font-family: MADE TOMMY;
  font-size: 14px;
  margin: 12px 0 28px;
  text-transform: capitalize;
  color: #05254c;
  opacity: .9;
}

.tesimon ul {
  display: flex;
  align-items: center;
}

.tesimon ul h3 {
  font-family: MADE TOMMY;
  font-size: 25px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0;
}

.tesimon ul h5 {
  font-family: MADE TOMMY;
  font-size: 21px;
  font-weight: 400;
  text-transform: capitalize;
  color: #0d6efd;
  margin-top: -5px;
}

.tesimon .shalow {
  line-height: 0px;
}

.tesimon li img {
  height: 60px;
  width: 60px;
  margin-right: 10px;
  margin-bottom: -10px;
}

section.tes {
  background: #f0f0f0;
}

.tesimon {
  background: #fff;
  padding: 45px 45px 45px 45px;
  border-radius: 20px;
  box-shadow: rgb(0 115 230 / 54%) 1px 3px 13px;
  width: 506px !important;
}

.tes h3 {
  line-height: 44px;
  margin-bottom: 45px;
}

/* .slick-slide.slick-current.slick-active.slick-center{
  background-color: #000;
  z-index: 1;
} */
.multiple-items-tes {
  position: relative;
  background: black;
  z-index: 1;
}

.multiple-items-tes1 {
  position: absolute;
  left: 0;
  top: 0;
}

.multiple-items-tes3 {
  position: absolute;
  right: 0;
  bottom: unset;
  top: unset;
}

.multiple-items-tes2 {
  position: absolute;
  right: 0;
  bottom: unset;
  z-index: 1;
  top: 85px;
  left: 50%;
  transform: translateX(-50%);
}

.conn9834 h3 {
  font-family: MADE TOMMY;
  font-size: 45px;
  font-weight: 400;
  text-transform: capitalize;
  color: #D86D00;
}

.conn9834 a {
  font-family: MADE TOMMY;
  font-size: 25px;
  font-weight: 500;
  text-transform: capitalize;
  color: #D86D00;
}

.mn {
  background: #ffc10740;
  border-radius: 35px;
  padding: 60px 60px 54px 60px;
  position: relative;
}

.mn::before {
  position: absolute;
  content: '';
  bottom: -135px;
  right: 0%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('./../assets/images/super.png');
  height: 620px;
  width: 360px;
  z-index: 1;
}

section.become {
  padding: 20px 0 100px 0;
  background: #f0f0f0;
}

.form934 select {
  word-wrap: normal;
  width: 100%;
  text-align: center;
  border: 1px solid #ada8a8;
  background: #faebd700;
  border-radius: 5px;
  padding: 7px;
  margin: 0 6px;
  font-family: MADE TOMMY;
  font-size: 15px;
  font-weight: 400;
  outline: none;
}

.sort {
  margin: 0 20px;
  font-family: MADE TOMMY;
  font-size: 15px;
  font-weight: 400;
  padding-top: 7px;
}

.form934 .op .tx h3 {
  background: #ffc1071f;
}

.form934 .op .tx h3 span {
  background: #ffc107;
}

.form934 .op .tx h3 {
  color: #ffc107;
}

.form934 .bx {
  padding: 0;
  position: relative;
  top: unset;
}

.form934 .ul {
  display: flex;
}

.form934 .op {
  padding: 0;
  margin: 0 0 0 18px;
}

.first09 li {
  margin-right: 22px;
}

.first09 img {
  width: 225px;
  object-fit: cover;
  border-radius: 50%;
}

.tags a {
  text-align: center;
  border: 1px solid #05254d;
  background: #faebd700;
  border-radius: 5px;
  padding: 6px;
  margin: 0 10px 10px 0;
  font-family: MADE TOMMY;
  font-size: 13px;
  font-weight: 400;
  outline: none;
  color: #05254d;
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tags .plus {
  margin-left: 10px;
  font-weight: 500;
}

.anchor321 {
  font-family: MADE TOMMY;
  font-size: 15px;
  text-transform: capitalize;
}

.form934 .crd {
  background: #fff;
  padding: 24px;
  border-radius: 20px;
}

.form934 .op {
  background: transparent;
}

.tab098 .crd {
  position: relative;
  /* margin: 28px 0; */
}

.tab098 .crd .sad63 {
  position: absolute;
  right: 25px;
  top: 28px;
  font-size: 19px;
}

.sad63 i.fa-solid.fa-star {
  color: #ffc107;
  margin-right: 6px;
}

.tab098 h2 {
  font-family: MADE TOMMY;
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
  color: #0073e6;
  margin-bottom: 11px;
}

.tab098 p {
  font-family: MADE TOMMY;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  color: #084298;
  margin-bottom: 0;
}

.tab098 a {
  font-family: MADE TOMMY;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  color: #0073e6;
}

.sess {
  margin-top: 37px;
}

/* .sess .sess1 {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 20px 30px 20px 20px;
  justify-content: space-between;
} */

.sess .sess1 li h2 {
  font-family: MADE TOMMY;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  color: #084298;
}

.sess .sess1 li .spnn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.sess .sess1 li .spn {
  font-family: MADE TOMMY;
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
  color: #70757a;
}

.sess .sess1 .li22 {
  width: 34%;
  display: flex;
  align-items: center;
}

.pri {
  font-family: MADE TOMMY;
  font-size: 21px;
  font-weight: 500;
  text-transform: capitalize;
  color: #0073e6;
  margin-right: 15px;
}

.sess .sess1 li button {
  width: 186px !important;
}

.sess .hee {
  font-family: MADE TOMMY;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  color: #45484c;
  margin-bottom: 0px;
}

.sess ul .img {
  margin-right: 14px;
}

.sess ul {
  display: flex;
  margin-bottom: 20px;
}

.sess .img img {
  height: 58px;
  width: 58px;
  border-radius: 15px;
  object-fit: cover;
}

.pricing .conn h3 {
  font-size: 45px !important;
  margin-top: 5px !important;
  line-height: 35px;
  margin-bottom: 35px;
  font-weight: 400;
}

.pri1 {
  background: #fff;
  border-radius: 20px;
  padding: 35px 35px 30px 35px;
  position: relative;
  overflow: hidden;
}

.pri1 .img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  filter: brightness(0) saturate(100%);
}

.pri1 h2 {
  color: #1C4980;
  font-family: MADE TOMMY;
  font-size: 32px;
}

.pri1 .pay {
  color: #1C4980;
  font-family: MADE TOMMY;
  font-size: 16px;
}

.pri1 h4 {
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 25px;
  font-weight: 500;
  color: #1c4980;
}

.pri1 button {
  width: 100%;
  height: 45px;
  border-radius: 10px;
  border: 0;
  background: #F4F4F4;
  font-size: 18px;
  font-family: 'MADE TOMMY';
  color: #666060;
  margin-bottom: 10px;
}

.pri1 li {
  font-size: 17px;
  font-family: 'MADE TOMMY';
  font-weight: 400;
  color: #1C4980;
  margin: 18px 0;
  text-align: left;
}

.pri1 li span {
  font-weight: 500 !important;
}

.crdin {
  text-align: left;
}

.defcontainer {
  max-width: 1440px !important;
}

.pricing .mardiv {
  margin: 100px 0;
}

.the-path {
  background: #D9D9D9;

}

.the-path .pri1 h4 {
  font-size: 20px;
  margin-top: 23px;
  margin-bottom: 0;
  font-weight: 500;
  color: #1c4980;
}

.the-path .pri1 h2 {
  color: #1C4980;
  font-family: MADE TOMMY;
  font-size: 24px;
  margin-top: 22px;
}

.the-path .pri1 {
  padding: 35px 35px 42px 35px;
  text-align: left;
}

.the-path img {
  margin-bottom: 10px;
}

.the-path .pri1 .plus {
  width: 105px;
  height: 42px;
  border-radius: 40px;
  margin-top: 13px;
  background: transparent;
  font-size: 18px;
  font-family: 'MADE TOMMY';
  color: #0073e6;
  margin-bottom: 0;
  border: 2px solid #0073e6;
  right: 30px;
  bottom: 34px;
}

.the-path .pri1 li {
  font-size: 18px;
  font-family: 'MADE TOMMY';
  font-weight: 400;
  color: #1C4980;
  margin: 18px 0;
  text-align: left;
  margin-top: 12px !important;
  text-align: center;
}

.the-path .mardiv {
  margin: 0;
  padding: 65px 0 115px 0;
}

.payying li {
  position: relative;
  padding-left: 20px;
}

.payying li::before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  content: "\f00c";
  font-size: 17px;
  font-family: "Font Awesome 6 Pro";
  color: #0caf0c;
  font-weight: 700;
}

.japii .li2 {
  text-align: left;
  position: unset;
}

.japii h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  text-transform: capitalize;
  color: #3B4045;
}

.japi-par {
  display: flex;
}

.japi-par h6 {
  font-size: 28px;
  color: #0073e6;
  font-weight: 600;
  letter-spacing: 2px;
  font-family: 'MADE TOMMY';
  text-align: left;
  text-transform: uppercase;
  -webkit-text-stroke-width: unset;
  -webkit-text-stroke-color: unset;
  letter-spacing: 0px;
  margin-bottom: 5px;
}

.japi-par li img {
  height: 72px;
  object-fit: cover;
  width: 72px;
  margin-right: 20px;
}

.wrld {
  background: #f0f0f0;
  padding: 90px 0;
}

.awd {
  margin: 90px 0 75px 0;
}

.cardio .one {
  width: 9%;
}

.cardio .two {
  width: 20%;
}

.cardio .three {
  width: 24%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.cardio .four {
  width: 22%;
}

.cardio ul {
  display: flex;
  width: 100%;
  background: #fff;
  padding: 14px 0px 14px 16px;
  border-radius: 20px;
  margin: 14px 0;
}

.cardio h2 {
  color: #455368;
  font-size: 17px;
  margin-bottom: 14px;
  font-family: 'MADE TOMMY';
}

.cardio h3 {
  color: #667795;
  font-size: 15px;
}

.cardio i {
  font-size: 15px;
  margin-right: 4px;
}

.cardio li {
  display: flex;
  align-items: center;
}

.cardio .one img {
  height: 90px;
  width: 90px;
  object-fit: cover;
  border: 1px solid #e9e0e0;
  border-radius: 15px;
}

/*//pagination//*/
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.pagination .btn-page {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3px;
  font-weight: 500;
  background: transparent;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  height: 36px;
  width: 56px;
  transition: .5s;
  font-size: 20px !important;
  padding: 13px;
  border: 1px solid #05254d;
}

.pagination .btn-page:hover {
  border: 1px solid #05254D;
}

.pagination .btn-page span {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #000;
  border-radius: 50%;
  font-size: 18px;
  transition: 0.5s;
}

.pagination .pagination-number {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  padding: 0;
  margin: 0;
}

.pagination .pagination-number li a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 38px;
  color: #05254D;
  font-size: 16px;
  margin: 0 3px;
  transition: 0.5s;
  background: #FFFFFF;
  border: 1px solid #05254D;
  border-radius: 3px;
  font-family: 'MADE TOMMY';
  font-weight: 400;
}

.pagination .pagination-number li .active {
  color: #ffffff;
  background: #05254D;
  border: 1px solid #05254D;
}

.pages2 {
  display: flex;
  justify-content: end;
}

.pages2 input[type="tex"] {
  width: 79px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 31px;
  margin-left: 13px;
  margin-top: -4px;
  padding: 10px;
}

.pages1,
.pages2 {
  color: #667795;
  font-size: 16px;
  font-family: 'MADE TOMMY';
  font-weight: 500;
}

.pagiii {
  margin-top: 55px;
}

.bgcar {
  background: #D9D9D9;
  border-radius: 20px;
  padding: 1px 10px;
}

.low {
  position: relative;
  background: #fff;
  padding: 40px 27px;
  border-radius: 19px;
}

.low h3 {
  font-family: MADE TOMMY;
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 2px;
  color: #1C4980;
}

.low p {
  font-family: MADE TOMMY;
  font-size: 12px;
  font-weight: 400;
  width: 77%;
  color: #1C4980;
  margin-top: 16px;
}

.low::before {
  position: absolute;
  content: '';
  left: unset;
  bottom: 0;
  right: 0%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('./../assets/images/prize1.png');
  top: 0px;
  width: 77px;
}

.lefty {
  position: relative;
  left: -120px;
}

.prizes {
  padding: 90px 0;
}

.yell p {
  font-family: MADE TOMMY;
  font-size: 13px;
  font-weight: 400;
  margin: 2px 0 0px;
  text-align: center;
  color: #5A6B87;
}

.yell .pri1 {
  padding: 12px 17px 17px 17px;
  text-align: center;
}

.yell {
  margin: 0;
  padding: 49px 0 85px 0;
}

.orfa {
  font-size: 39px !important;
}

.bgg-img img {
  width: 100%;
  object-fit: cover;
}

.yell textarea {
  resize: vertical;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  border: 1px solid #cccccccf;
  padding: 20px;
}

.yell textarea::placeholder {
  color: #ccc;
  font-family: MADE TOMMY;
  font-size: 24px;
  margin-top: 22px;
}

.stars {
  background: #F7F9FA;
  width: 70%;
  padding: 9px;
  border-radius: 10px;
  margin: 15px auto;
}

.sham {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.sham .one {
  width: 20%;
}

.sham .two {
  width: 60%;
}

.sham .three {
  width: 20%;
}

.progress {
  display: flex;
  height: 10px;
}

.the-path1 .pri1 li {
  font-size: 17px;
  margin: 3px 0 !important;
}

.the-path1 .rat {
  font-family: 'MADE TOMMY';
  font-weight: 400;
  color: #1C4980;
  margin: 18px 0 20px 0;
}

.the-path1 .pri1 {
  padding: 16px 20px 32px 20px;
}

.starss {
  display: flex;
}

.stars i {
  color: #bdbaba;
}

.bluee {
  color: #0073E6 !important;
}

.starss i.fa-solid.fa-star {
  color: #bdbaba;
  font-size: 22px;
  margin: 20px 10px 0;
  cursor: pointer;
}

.ratingg .name {
  color: #1C4980;
  font-family: MADE TOMMY;
  font-size: 24px;
  margin: 0 15px;
}

.ratingg .date {
  color: #3e5c81;
  font-family: MADE TOMMY;
  font-size: 15px;
}

.ratingg .av img {
  height: 33px;
  width: 33px;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
  top: 3px;
}

.ratingg p {
  font-family: MADE TOMMY;
  font-size: 17px;
  font-weight: 400;
  margin: 10px 0 0px;
  text-align: left;
  color: #27497D;
}

.ratingg {
  margin: 29px 0;
  border-bottom: 1px solid #ccc;
  padding-bottom: 35px;
}

.pagiiii {
  margin-top: 55px;
  background: #fff;
  padding: 8px 20px;
  border-radius: 11px;
}

.pagiiii .pages1,
.pagiiii .pages2 {
  color: #667795;
  font-size: 16px;
  font-family: 'MADE TOMMY';
  font-weight: 500;
  text-align: left;
  height: 100%;
  display: flex;
  align-items: center;
}

.yell2 {
  padding: 65px 0 45px 0 !important;
}

/* /// mos /// */


/* compete css start here */
.navy-blue-button {
  border-radius: 10px;
  background: #05254D;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  color: #fff;
  padding: 15px 50px;
}

.primary-blue-button {
  border-radius: 10px;
  background: #0073E6;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  color: #fff;
  padding: 15px 50px;
}

.more-exiting-oportunity .down-txt {
  position: relative;
  font-weight: 700;
  top: 60px;
  left: 20px;
  color: #05254D;
}

.heading {
  text-align: center;
}

.more-exiting-oportunity h5 {
  color: #05254D;
  font-family: MADE TOMMY;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 131.5%;
  letter-spacing: -0.18px;
  text-transform: capitalize;
  width: 80%;
  margin-top: 24px;
}

.more-exiting-oportunity h3 {
  font-family: MADE TOMMY;
  font-size: 45px;
  font-weight: 400;
  text-transform: capitalize;
  color: #05254D;
}

.more-exiting-oportunity h3 span {
  color: #0073e6;
}

.more-exiting-oportunity p {
  font-family: MADE TOMMY;
  font-size: 19px;
  font-weight: 400;
  margin: 12px 0 28px;
  /* text-transform: capitalize; */
  color: #3B4045;
}

/* compete css ends here */

/* trending oportunities start here */

.trending-oportunities h3 {
  font-family: MADE TOMMY;
  font-size: 45px;
  font-weight: 400;
  text-transform: capitalize;
  color: #05254D;
}

.trending-oportunities h3 span {
  color: #0073e6;
}

.trending-oportunities p {
  font-family: MADE TOMMY;
  font-size: 19px;
  font-weight: 400;
  margin: 12px 0 28px;
  text-transform: capitalize;
  color: #3B4045;
}

.trending-oportunities .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  border-radius: 10px;
  background: #a4c3e8;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  color: #05254D;
  padding: 10px 20px;
  margin: 10px;
  font-family: MADE TOMMY;
  font-weight: 400;
}

.trending-oportunities .nav-pills .nav-link {
  border-radius: 10px;
  background: #05254d00;
  border: 1px solid;
  color: #05254D;
  padding: 10px 20px;
  margin: 10px;
  font-family: MADE TOMMY;
  font-weight: 400;
}

/* trending oportunities ends here */

/* explore-oportunities css start here */

.explore-oportunities h3 {
  font-family: MADE TOMMY;
  font-size: 45px;
  font-weight: 400;
  text-transform: capitalize;
  color: #05254D;
}

.explore-oportunities h3 span {
  color: #0073e6;
}

.explore-oportunities p {
  font-family: MADE TOMMY;
  font-size: 19px;
  font-weight: 400;
  margin: 12px 0 28px;
  text-transform: capitalize;
  color: #3B4045;
}

/* explore-oportunities css ends here */

/* oportunites-for-various-ind css */

.oportunites-for-various-ind .vari-img {
  margin: 100px 0 !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 32px;
}

/* oportunites-for-various-ind css eds */

/* how dose it works start here */

.how-dose-it-works .vari-img {
  margin: 100px 0 !important;
}

/* how dose it works ends here */


/* employers css start here */

.main-employers {
  background-image: url('./../assets/images/bannernew.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 100px 0;
}

.main-employers .main-headings {
  padding: 0 0 50px;
}

.main-employers h4 {
  font-family: MADE TOMMY;
  font-size: 65px;
  font-weight: 400;
  color: #05254D;
  text-transform: capitalize;
}

.main-employers span {
  color: #0073e6;
}

.main-employers p {
  font-family: MADE TOMMY;
  font-size: 19px;
  font-weight: 400;
  margin: 12px 0 28px;
  text-transform: capitalize;
  color: #3B4045;
}

.main-employers .main-headings {
  text-align: center;
}

/*  */
.main-audience-you-need .main-heading {
  padding: 0 0 50px;
  text-align: center;
}

.main-audience-you-need h4 {
  font-family: MADE TOMMY;
  font-size: 35px;
  font-weight: 400;
  text-transform: capitalize;
  color: #05254D;
}

.main-audience-you-need span {
  color: #0073e6;
}

.main-audience-you-need p {
  font-family: MADE TOMMY;
  font-size: 19px;
  font-weight: 400;
  margin: 12px 0 28px;
  text-transform: capitalize;
  color: #3B4045;
}

/*  */
.main-We-simplify-your-hiring .bg-img {
  background-image: url('./../assets/images/empoloyers/syh.png');
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 100px 0;
  padding: 20px 0 75px 0;
}

.main-We-simplify-your-hiring .main-headings {
  text-align: center;
  margin: 50px 0;
}

.main-We-simplify-your-hiring h4 {
  color: #05254D;
  font-size: 35px;
  text-transform: capitalize;
}

.main-We-simplify-your-hiring span {
  color: #FD9801;
}

/* employers css ends here */


/* perks and benefits css start here */

.perks-and-benefits {
  background-color: #F0F0F0;
  padding: 65px 0;
}

.perks-and-benefits .cont-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: rgba(107, 177, 251, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
}

.perks-and-benefits .pb-img {
  width: 110px;
  height: 110px;
  flex-shrink: 0;
}

.perks-and-benefits .cont-box-txt {
  padding: 0 10px 0;
  font-family: MADE TOMMY;
  font-weight: 500;
  text-transform: capitalize;
}

.perks-and-benefits .blue {
  border-radius: 30px;
  background: rgba(107, 177, 251, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #016FE7;
}

.perks-and-benefits .green {
  border-radius: 30px;
  background: rgba(103, 176, 84, 0.25);
  color: #2F8E5B;

}

.perks-and-benefits .pink {
  border-radius: 30px;
  background: rgba(180, 48, 29, 0.15);
  color: #F84752;

}

.perks-and-benefits .yellow {
  border-radius: 30px;
  background: rgba(242, 192, 52, 0.25);
  color: #FFBA05;

}

/* perks and benefits css ends here */

/* life at unstop */

.life-at-unstop {
  padding: 50px 0;
  background-color: #F0f0f0;
}

/* life at unstop */

/* life-at-unstop-with-tabs css start here */

.life-at-unstop-with-tabs {
  background-color: #f0f0f0;
}

.life-at-unstop-with-tabs {
  padding: 75px 0 130px 0;
}

.life-at-unstop-with-tabs::before {
  position: absolute;
  content: '';
  /* background-image: url('./../assets/images/wave-one.png'); */
  width: 100%;
  height: 580px;
  z-index: -1;
  right: -460px;
  background-repeat: round;
}

.life-at-unstop-with-tabs::after {
  position: absolute;
  content: '';
  /* background-image: url('./../assets/images/wave-two.png'); */
  width: 100%;
  height: 545px;
  left: 0px;
  z-index: -1;
  bottom: -20px;
}

.life-at-unstop-with-tabs .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #05254D;
}

.life-at-unstop-with-tabs .nav-pills .nav-link:hover {
  color: #fff;
  background-color: #05254d;
}

.life-at-unstop-with-tabs .nav-link:hover {
  background-color: #05254d;
  color: #ffffff !important;
  border: 3px solid #05254d;
}

.life-at-unstop-with-tabs .nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 10px;
  padding: 10px 30px 10px;
  margin: 0px 10px;
  font-family: MADE TOMMY;
  font-weight: 500;
  text-transform: capitalize;
  color: #05254D;
}

.life-at-unstop-with-tabs .tab-img {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}


.life-at-unstop-with-tabs .accordion .accordion-item button {
  font-family: MADE TOMMY;
  font-size: 16px;
  font-weight: 500;
  color: #05254c;
  padding: 20px 20px;
  background-color: #F0F0F0;
}

.life-at-unstop-with-tabs .accordion .accordion-item .icon-div {
  position: relative;
}

.life-at-unstop-with-tabs .accordion .accordion-item .icon-div i {
  font-size: 25px;
  position: absolute;
  font-weight: 300;
  top: 21px;
  left: 210px;
  color: #ffffff;
}

.life-at-unstop-with-tabs .accordion-body {
  background-color: #F0F0F0;
  font-family: MADE TOMMY;
  font-weight: 400;
  text-transform: capitalize;
}

.life-at-unstop-with-tabs .accordion .accordion-item .lst-btn {
  font-family: MADE TOMMY;
  font-size: 16px;
  color: #ffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  padding: 20px 20px;
  width: 100%;
  background-color: #05254c;

}

/* life-at-unstop-with-tabs css ends here */


/* /// mos /// */

.newwwas img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.compete {
  padding-top: 100px;
}

.the_padd {
  padding: 100px 0 75px 0;
}

.hello_fea {
  padding-top: 70px;
  margin: 0 !important;
}

section.more-exiting-oportunity.pt-5 {
  padding: 150px 0 33px 0 !important;
}

.more-exiting-oportunity img {
  width: 100%;
  border-radius: 45px;
  object-fit: cover;
  height: 215px;
  overflow: hidden;
}

.cerformin {
  position: relative;
  background: #d9d9d933;
  padding: 125px 0 50px !important;
}

.conn button.priii:hover {
  background: transparent;
  color: #ffffff;
}

.width-changing {
  width: 90%;
}

.learnbg123 .conn {
  margin-top: 135px;
}

img.checkkr {
  width: 45px;
  margin: 0 20px;
}

.marbtn {
  width: 245px !important;
  margin: 58px 0 0 0 !important;
}

img.checkkr1 {
  width: 55px;
  margin: 0 20px;
}

button.btndon {
  width: 120px !important;
  margin: 0 0 0 15px !important;
  font-size: 11px;
}

.nahin {
  display: flex;
  margin-top: 23px;
}

.nahin img {
  width: 226px;
  height: 83px;
  object-fit: cover;
  border-radius: 20px;
}

.nahin p {
  font-size: 18px;
  margin: 0px 0 0px 25px;
  display: flex;
  align-items: center;
}

.nomin::before {
  background-image: url('./../assets/images/nomin.png');
}

.mrx {
  padding: 0px 0;
  background: #f0f0f0;
}

.mentees ul {
  display: flex;
  align-items: center;
  padding: 25px 0;
}

.mentees .menti {
  width: 69%;
  position: relative;
  top: 16px;
  right: 35px;
}

.mentees .secli {
  display: flex;
  justify-content: end;
}

.mentees h3 {
  font-size: 57px;
  line-height: 59px;
}

.mentees h3 span {
  color: #05254d;
}

.menbtn .btn {
  background: #05254d;
  border: 2px solid #05254d !important;
  margin-top: 20px;
}

.mentees .theyellow {
  font-family: MADE TOMMY;
  font-size: 87px !important;
  font-weight: 700;
  text-transform: capitalize;
  color: #F97A01;
  line-height: 84px !important;
  margin-bottom: 27px;
}

.mentees {
  padding: 65px 0;
}

.conin {
  position: relative;
  right: -65px;
}

.conin1 {
  padding-left: 45px;
}

.mentees .coli1 {
  width: 80%;
  position: relative;
  top: -95px;
}

.mentees .coli3 {
  width: 80%;
  position: relative;
  bottom: -85px;
}

.alluw {
  position: relative;
  z-index: 1;
}

.works-img {
  width: 100% !important;
  border-radius: 45px !important;
  height: unset !important;
  overflow: hidden !important;
  margin-top: 65px !important;
}

.quota {
  width: 67%;
  margin-top: 29px;
}

.tst09 ul {
  display: flex;
}

.conn p {
  font-size: 17px;
  margin: 12px 0 28px;
}

.tst09 ul img {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  object-fit: cover;
}

.the-shell h3 {
  font-family: MADE TOMMY;
  font-size: 22px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 23px;
  margin-left: 13px;
}

.tst09 .the-shell h3 span {
  color: #0073e6;
  font-size: 17px;
}

section.tst09 {
  padding: 115px 0 50px 0;
  background: #f0f0f0;
}

.conn.the-shell {
  background: #fff;
  padding: 30px 20px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  width: 470px;
}

.conn.the-shell p {
  font-size: 17px;
  margin: 12px 0 28px;
}

.shell2 {
  position: relative;
  top: 90px;
  z-index: 1;
  left: -100px;
}

.shell3 {
  position: relative;
  left: -200px;
  top: 200px;
}

section.tst0923 {
  padding: 115px 0 210px 0;
  background: #f0f0f0;
}

.the-quote {
  position: absolute;
  width: 57%;
  right: 55px;
}

.main-audience-you-need .conn span {
  font-family: MADE TOMMY;
  font-size: 40px;
  font-weight: 700;
  text-transform: capitalize;
  text-align: center;
}

.main-audience-you-need .conn h3 {
  font-family: MADE TOMMY;
  font-size: 25px;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
}

.cirdl img {
  width: 55%;
}

.cirdl {
  display: flex;
  justify-content: center;
  align-items: center;
}

.war-bg {
  background: #FDB902;
  padding: 23px;
  border-radius: 20px;
}

.war-bg .conn {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  justify-content: center;
  border-radius: 14px;
  padding: 35px 0;
}

.main-We-simplify-your-hiring .box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #FD9801;
  padding: 20px;
  border-radius: 20px;
}

.main-We-simplify-your-hiring h3 {
  font-family: MADE TOMMY;
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
  color: #fff;
  margin-top: 15px;
}

.olw h3 {
  font-family: MADE TOMMY;
  font-size: 35px;
  font-weight: 600;
  text-transform: capitalize;
}

.uni a {
  text-align: center;
  border: 1px solid #86888b;
  background: #faebd700;
  border-radius: 5px;
  padding: 12px;
  margin: 0 4px 0 0;
  font-family: MADE TOMMY;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  color: #144f98;
  width: unset;
}

.olw .tags {
  display: flex !important;
  align-items: center;
  flex-wrap: wrap-reverse;
}

.uni {
  height: 65px;
}

.helwrl {
  background: #FFC835;
  border-radius: 20px;
  padding: 40px 45px 30px;
}

.orpro .conn h3 {
  margin-bottom: 60px;
}

.orpro .row.justify-content-center {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
}

.thesolo .shell3 {
  position: relative;
  left: -200px;
  top: 110px;
}

.thesolo .shell2 {
  position: relative;
  top: 0px;
  z-index: 1;
  left: 30px;
}

.thesolo .conn.the-shell {
  background: #FFC835;
}

.tabsi button {
  border: 1px solid #ffc107;
  background: transparent;
  border-radius: 17px;
  margin: 20px 0;
}

.tabsi {
  height: 100%;
  display: grid;
}

section.our-pro.orpro {
  background: #f0f0f0;
  padding: 0 0 100px 0;
}

.cerili {
  position: relative;
  background: #f0f0f0;
  padding: 60px 0 150px !important;
}

section.main-We-simplify-your-hiring {
  background: #f0f0f0;
}

section.main-audience-you-need {
  background: #f0f0f0;
}

.main-employers {
  position: relative;
  width: 100%;
  padding: 100px 0;
  background: #f0f0f0;
}

.main-employers::before {
  content: '';
  position: absolute;
  inset: 0;
  height: 50%;
  background-image: url('./../assets/images/bannernew.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.main-employers img {
  z-index: 1;
  position: relative;
  height: 550px;
  width: 100%;
}

.hev .conn {
  margin-top: 85px;
}

.hev .jap2 {
  position: relative;
  right: -60px;
  top: unset;
}

.hev .ocean {
  width: 200px;
}

.cud {
  background-color: #0073E6;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 55px 30px;
  border-radius: 28px;
}

.cud h3 {
  font-family: MADE TOMMY;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color: #FFFFFF;
  text-transform: capitalize;
  opacity: .5;
}

.cud h2 {
  font-family: MADE TOMMY;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #FFFFFF;
  text-transform: capitalize;
  opacity: 1;
  margin-top: 15px;
}

.cudi {
  background-color: #0073E6;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 29px 30px;
  border-radius: 28px;
}

.cudi h2 {
  font-family: MADE TOMMY;
  font-size: 27px;
  font-weight: 500;
  text-align: center;
  color: #FFFFFF;
  text-transform: capitalize;
  opacity: 1;
  margin-top: 0;
}

.cudi span {
  font-size: 19px;
}

.avi ul {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.avi ul li img {
  width: 120px;
  object-fit: contain;
  margin-right: 15px
}

.avi ul li h3 {
  font-family: MADE TOMMY;
  font-size: 25px;
  font-weight: 400;
  text-transform: capitalize;
}

.avi ul li p {
  font-size: 15px;
  margin: 4px 0 28px;
}

.cud {
  position: relative;
}

.thevalimg {
  position: absolute;
  left: 0;
  top: 0;
  height: 90px;
  width: 81px;
}

.jackle .cud {
  background-color: #0073E6;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 55px 30px 34px 30px;
  border-radius: 28px;
}

.jackle {
  background: #f0f0f0;
  padding-top: 85px;
  padding-bottom: 104px;
}

button.accordion-button {
  color: #05254D;
  font-family: MADE TOMMY;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  background: transparent;
}

.ind .main-div .slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  height: 112.258px !important;
  margin-bottom: -13px;
  margin-top: 18px;
}

.accordion-button:not(.collapsed) {
  color: #000000;
  background-color: transparent;
  box-shadow: unset;
}

.accordion-item {
  background-color: #fff0;
  border: 0;
}

.accordion-item:first-of-type .accordion-button {
  font-size: 19px;
  padding: 0px 0 0 0;
}

.b1 {
  background-color: #ff7a00 !important;
}

.b2 {
  background-color: #006fde !important;
}

.b3 {
  background-color: #fdb902 !important;
}


.marquee {
  height: 119px;
  position: relative;
  overflow-x: hidden;
  line-height: 36px;
  overflow-y: hidden;
}

section.mar {
  position: relative;
  top: -75px;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 15s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

.track2 {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: okanim 15s linear infinite !important;

}

@keyframes okanim {
  from {
    transform: translateX(-50%) !important;
  }

  to {
    transform: translateX(0%) !important;
  }
}

 .styleWidth {
  /* width: 140px !important; */
  margin: 0 20px;
  /* height: 140px; */
  /* aspect-ratio: 2/1; */

} 
.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  width: 150px; /* Adjust width as needed */
  height: 100px; /* Adjust height as needed */
  overflow: hidden;
}

.slider img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.shad {
  box-shadow: #0d6efda8 0px 5px 15px;
  border: 1px solid #ccc;
  background: #fff;
}

.bor {
  border-radius: 15px;
}

.owl-carousel .item {
  padding: 0 20px;
}

.owl-carousel .item img {
  background-color: #fff;
}

.heigy {
  height: 235px;
}

.cer .box img {
  height: 205px !important;
}

.mrx video {
  /* background: #000;
  height: 632px;
  margin-top: 35px; */
  width: 100%;
}


/* // ************ MemberShip Page ************* // */

.pricingTable {
  background: radial-gradient(#fff, #f9f9f9, #eee);
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  padding: 0 0 35px;
  border-radius: 25px;
  box-shadow: 5px 0 10px rgb(0 0 0 / 30%);
  position: relative;
}

.pricingTable .pricingTable-header {
  color: #fff;
  background: #011452;
  padding: 35px 20px 0;
  border-radius: 25px 25px 0 0;
  margin: 0 0 50px;
}

.pricingTable .price-value .currency {
  font-size: 35px;
  font-weight: 300;
  line-height: 35px;
  vertical-align: top;
  display: inline-block;
}

.pricingTable .price-value .amount {
  font-size: 58px;
  font-weight: 700;
  line-height: 45px;
  display: inline-block;
}

.pricingTable .price-value .duration {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0 0 0 -5px;
  display: inline-block;
}

.pricingTable .title {
  color: #fff;
  background: #efefef69;
  font-size: 33px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px 20px;
  margin: 0;
  border-radius: 5px;
  display: inline-block;
  transform: translateY(25px);
}

.pricingTable .pricing-content {
  text-align: left;
  padding: 0;
  margin: 20px 0 40px;
  list-style: none;
  display: inline-block;
}

.pricingTable .pricing-content li {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 25px;
  padding: 0 20px 0 52px;
  margin: 0 0 15px;
  position: relative;
}

.pricingTable .pricing-content li:last-child {
  margin: 0;
}

.pricingTable .pricing-content li:before {
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  color: #ffffff;
  color: #fff;
  background: #00df78;
  font-size: 13px;
  font-weight: 900;
  text-align: center;
  line-height: 21px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 20px;
}

.pricingTable .pricing-content li.disable:before {
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  color: #ffffff;
}

.pricingTable .pricingTable-signup a {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-transform: uppercase;
  padding: 12px 45px;
  border-radius: 15px;
  border: 2px solid #01145200;
  display: inline-block;
  transition: all 0.3s ease;
}

.pricingTable .pricingTable-signup a:hover {
  color: #fff;
  background: #011452;
  box-shadow: 10px 10px 0 4px rgba(0, 0, 0, 0.1);
}

.membership {
  margin-top: 0;
  padding: 100px 0;
  position: relative;
}

/* /// 3d animation /// */

#body {
  background: transparent;
  min-height: auto;
}

#body .card-main-wrapper {
  perspective: 350px;
  width: auto;
  height: 100%;
}

#body .card-main-wrapper .card {
  transform-style: preserve-3d;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 3.55);
  background: transparent;
  border-radius: unset !important;
  border: 0 !important;
}

#body .card-main-wrapper:hover .card,
#body .card-main-wrapper:hover .inner {
  transition: all 1s !important;
}


.card {
  background-color: transparent !important;
  background-clip: transparent !important;
  border: transparent !important;
}

.cer .i-height img {
  height: 85px !important;
  margin-bottom: 20px;
}

.pad-day {
  padding: 90px 60px 40px !important;
}

.learnbg .top-mar {
  margin-top: 130px;
}

.mt-opbck {
  margin-top: 150px;
}

.qut-vid-1 {
  position: absolute;
  right: 0;
  bottom: -100px;
  width: 195px;
  opacity: .5;
}

.qut-vid-2 {
  position: absolute;
  left: 0;
  top: -100px;
  width: 195px;
  opacity: .5;
}

.tech-pg-set img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.tech-pg-txt {
  color: #1C4980;
  font-family: 'MADE TOMMY';
  font-size: 24px;
  font-weight: 500;
}

.compete-pad-btm {
  background: #f0f0f0;
  padding: 0 0 40px 0 !important;
}

.slid-pup {
  padding: 100px 0 70px 0;
}

.slid-pup img {
  height: 245px;
}

@media only screen and (min-width: 1921px) and (max-width: 7368px) {}

@media only screen and (min-width: 1600px) and (max-width: 1920px) {}

@media only screen and (min-width: 1445px) and (max-width: 1599px) {}

@media only screen and (min-width: 1366px) and (max-width: 1444px) {}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tab098 a {
    font-family: MADE TOMMY;
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
    color: #0073e6;
  }


  .banner h5 {
    font-size: 60px;
    line-height: 67px;
    margin: 15px 0 24px 0;
  }

  .s-div {
    height: 227px;
  }

  .op .tx h2 {
    font-size: 25px;
  }

  .suri {
    padding: 91px 0 201px;
  }

  .conn h3 {
    font-size: 39px;
  }

  .expect {
    padding: 27px 0 90px;
  }

  .expect .conn {
    margin-top: 40px;
  }

  .bgnew {
    margin-top: 114px;
  }

  .cer .conn.text-center.mb-5 {
    margin-bottom: 62px !important;
  }

  .tb-crd .padd-div {
    padding: 0 20px 23px;
  }

  .heigy {
    height: 200px;
  }

  .inner-head-b h3 {
    font-size: 28px;
    margin-top: 12px;
  }

  .sess .sess1 {
    padding: 20px 15px 20px 10px;
  }

  .mrx {
    padding: 35px 0;
  }

  .more-exiting-oportunity .down-txt {
    top: 51px;
    left: 15px;
    font-size: 15px;
  }

  .more-exiting-oportunity img {
    height: 180px;
  }

  .mt-opbck {
    margin-top: 80px;
    padding-bottom: 0;
  }

  .mt-opbck .bx h2 {
    font-size: 28px;
  }

  .section {
    left: 0;
    margin: 0;
  }

  .pricing .conn h3.title {
    font-size: 40px !important;
  }

  .main-employers img {
    height: 650px;
  }

  .main-We-simplify-your-hiring .bg-img {
    background-size: 100% 90%;
  }

  .thesolo .shell2 {
    left: 0;
  }

  .japi-par li img {
    margin-right: 55px;
  }

  .cardio ul {
    padding: 14px 60px 14px 10px;
  }

  section#clients_page_bnr_sec li {
    width: 48%;
  }

  .platform .row {
    align-items: center;
  }

  .op .bx h2 {
    font-size: 22px;
    line-height: 31px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .copi {
    margin-bottom: 60px;
}
nav.nav_bottom {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 55px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    display: flex;
    overflow-x: hidden;
    z-index: 2;
    padding-top: 10px;
    justify-content: center;
}
ul.mobile-tabs {
    flex-direction: row;
    display: flex;
}

ul.mobile-tabs li.nav-item {
  /* Optional: Adjust margin to add space around items */
  margin: 0 14px;
}

a.nav-link {
    margin: 0 7px !important;
}
li.tab-hide {
    display: none;
}
.op .tx h3 {
  margin: 8px 0px 9px 0;
}
.op .bx h2 {
  font-size: 13px;
}

.news .conn h3 {
  z-index: 1;
  position: relative;
}
.news:before {
  top: -27px;
}
.pri {
  font-size: 16px;
}

footer .bg-img {
  height: 500px;
}
.accordion-item:first-of-type .accordion-button {
  font-size: 14px;
}
.col-12 {
  z-index: 1;
  position: relative;
}
.view2 {
  font-size: 13px;
  margin-top: 50px;
}
.panel {
  text-align: center;
}
.view1 h3 {
  width: 100%;
}

.view1 .timing {
  left: 0;
}
.view2 {
  margin-top: 25px !important;
  margin-bottom: 25px;
}
.copi {
  font-size: 11px !important;
}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tab098 a {
    font-family: MADE TOMMY;
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
    color: #0073e6;
  }
  .ind2 {
    padding: 80px 0 20px !important;
}
.tabbs .box .inner-head-b p {
  font-size: 15px;
  margin: 10px 0 16px;
}
.conn button.btn {
  margin-bottom: 10px;
}

  .banner h5 {
    font-size: 60px;
    line-height: 67px;
    margin: 15px 0 24px 0;
  }

  .s-div {
    height: 227px;
  }

  .op .tx h2 {
    font-size: 25px;
  }

  .suri {
    padding: 91px 0 201px;
  }

  .conn h3 {
    font-size: 39px;
  }

  .expect {
    padding: 27px 0 90px;
  }

  .expect .conn {
    margin-top: 40px;
  }

  .bgnew {
    margin-top: 114px;
  }

  .cer .conn.text-center.mb-5 {
    margin-bottom: 62px !important;
  }

  .tb-crd .padd-div {
    padding: 0 20px 23px;
  }

  .heigy {
    height: 200px;
  }

  .inner-head-b h3 {
    font-size: 28px;
    margin-top: 12px;
  }

  .sess .sess1 {
    padding: 20px 60px 20px 10px;
  }

  .mrx {
    padding: 35px 0;
  }

  .more-exiting-oportunity .down-txt {
    top: 51px;
    left: 15px;
    font-size: 15px;
  }

  .more-exiting-oportunity img {
    height: 180px;
  }

  .mt-opbck {
    margin-top: 80px;
    padding-bottom: 0;
  }

  .mt-opbck .bx h2 {
    font-size: 28px;
  }

  .section {
    left: 0;
    margin: 0;
  }

  .pricing .conn h3.title {
    font-size: 40px !important;
  }

  .main-employers img {
    height: 500px;
  }

  .main-We-simplify-your-hiring .bg-img {
    background-size: 100% 90%;
  }

  .thesolo .shell2 {
    left: 0;
  }

  .japi-par li img {
    margin-right: 55px;
  }

  .cardio ul {
    padding: 14px 60px 14px 10px;
  }

  section#clients_page_bnr_sec li {
    width: 48%;
  }

  .platform .row {
    align-items: center;
  }

  .op .bx h2 {
    font-size: 28px;
  }








}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab098 a {
    font-family: MADE TOMMY;
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
    color: #0073e6;
  }
  .ex {
    padding: 100px 0;
}
.ind2 {
  padding: 50px 0;
}
  .condi .con img{
    width: 50% !important;
}

ul.navbar-nav.mb-2.mb-lg-0 {
  flex-direction: row;
}

  /* home page started */

  .life-at-unstop-with-tabs .accordion .accordion-item button {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .perks-and-benefits .cont-box {
    padding: 20px;
  }

  .platform p {
    font-size: 17px;
    font-weight: 400;
  }

  .platform .img {
    text-align: center;
  }

  .platform .img img {
    width: 85%;
  }

  .platform .pll .conn h3.kell {
    font-size: 40px !important;
  }

  .platform .pll .conn p {
    font-size: 20px;
    margin: 20px 0 !important;
  }

  .boxii img {
    width: 35%;
  }

  .boxii p {
    font-size: 18px;
    padding: 0 50px;
  }

  .ind .box .conn p {
    font-size: 21px;
  }

  .cardio h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .cardio h3 {
    font-size: 17px;
  }

  .cardio .one img {
    height: 100px;
    width: 100px;
  }

  .low h3 {
    font-size: 30px;
  }

  .compss {
    padding: 40px 80px;
    margin-top: 40px;
  }

  .low p {
    font-size: 16px;
  }

  .main-We-simplify-your-hiring .main-headings {
    margin: 50px 0 70px;
  }

  .main-We-simplify-your-hiring h4 {
    font-size: 30px;
  }

  .main-We-simplify-your-hiring .col-md-4 {
    margin-top: 10px;
  }

  .main-We-simplify-your-hiring .box {
    padding: 25px;
  }

  .main-We-simplify-your-hiring .box img {
    width: 85%;
  }

  .main-We-simplify-your-hiring h3 {
    font-size: 18px;
  }

  .main-audience-you-need .conn h3 {
    font-size: 18px !important;
  }

  .main-audience-you-need .conn span {
    font-size: 24px;
  }

  .compete {
    padding: 100px 0 40px 0 !important;
  }

  .conn9834 h3 {
    font-size: 38px !important;
  }

  .tst09 ul {
    align-items: center;
  }

  .tst09 ul img {
    height: 60px;
    width: 60px;
  }

  section.tst0923 ul li h3 {
    font-size: 35px !important;
  }

  .tst09 .the-shell h3 span {
    font-size: 18px;
  }

  .alluw {
    width: 80%;
    right: -140px;
  }

  div#home .conn h3 {
    font-size: 31px !important;
    padding: 20px 0 0;
  }

  div#home .conn p {
    font-size: 19px !important;
  }

  .cer .i-height img {
    margin-bottom: 20px;
  }

  .tb-crd li {
    font-size: 18px;
    margin: 10px 0;
  }

  .tabbs .nav-tabs .nav-link {
    font-size: 17px !important;
    width: 300px !important;
  }

  .tabbs .nav-tabs .nav-link img {
    width: 60%;
  }

  div#myTabContent .img-div {
    text-align: center;
  }

  div#myTabContent .img-div img {
    width: 75%;
  }

  section.clients_page_five_sec:before {
    width: 18% !important;
    height: 22% !important;
    top: 190px !important;
    left: 26% !important;
  }

  section.clients_page_five_sec::after {
    width: 18% !important;
    height: 22% !important;
    top: 75% !important;
    right: 25% !important;
  }

  .view1 ul li {
    margin: 15px 0;
  }

  .clients_page_slder .slick-prev {
    width: 30% !important;
  }

  .view1 h2 {
    margin: 40px 0px 30px 0;
  }

  .clients_page_slder .slick-prev:before,
  .clients_page_slder .slick-next:before {
    position: absolute !important;
    top: 30% !important;
    left: 40% !important;
    font-size: 22px !important;
  }

  .cli_sld_img_main h5 {
    font-size: 25px !important;
  }

  .cli_sld_img_main img {
    width: 20% !important;
    margin-right: 20px !important;
  }

  .cli_sld_img_main p {
    font-size: 18px !important;
  }

  .client_sldr_text_main_box p {
    font-size: 16px !important;
    padding-top: 25px !important;
  }

  header {
    height: 225px;
  }

  .clients_inner_tabs .nav-tabs .nav-link {
    font-size: 19px;
    padding: 18px 30px;
  }

  .clients_inner_tabs h3 {
    font-size: 22px;
    padding: 25px 20px;
  }

  .mega {
    margin-top: 0;
    transition: 1s;
    z-index: 999;
    position: relative;
  }

  .cls {
    display: grid;
    padding: 14px 7px 9px 0;
    box-shadow: #107cc647 0px 2px 8px 0px;
    border-radius: 4px;
    margin-top: 0;
  }

  .cls .cl2,
  .cls .cl3,
  .cls .cl5 {
    width: unset;
  }

  ul.brr a {
    font-family: 'MADE TOMMY';
    font-size: 19px;
  }

  ul.brr {
    border-right: 1px solid #8b8b8b;
    padding-left: 6px;
    margin: 13px 0;
  }

  .cls .cl1,
  .cls .cl4 {
    width: unset;
  }

  .mega {
    left: -850px;
  }

  .megaShow {
    margin-top: unset !important;
    left: unset !important;
  }

  .navbar-light .navbar-toggler {
    display: none;
  }

  header .logo {
    max-width: 200px !important;
    height: 90px;
  }

  header .width {
    width: unset;
  }

  a.navbar-brand {
    position: absolute;
    top: 20px;
    right: 15px;
  }

  .navbar-light .navbar-brand {
    margin: 0;
    padding: 5px 15px 5px 16px;
    font-size: 22px;
  }

  button.btn {
    padding: 10px;
    width: 200px !important;
    margin: 0 7px;
    font-size: 20px;
  }

  header .d-flex {
    justify-content: center;
    position: relative;
    z-index: 9;
  }

  .banner h6 {
    font-size: 20px;
    letter-spacing: 2px;
  }

  .banner h5 {
    font-size: 44px;
    font-weight: 400;
    line-height: 50px;
    margin: 20px 0;
  }

  .bn-slider1 li.slick-active button {
    width: 85px !important;
  }

  .bckkk::before {
    background-position: top;
    height: 100%;
    animation: unset;
    content: unset !important;
  }

  .sl {
    margin-top: 15px;
    object-fit: cover;
  }

  .banner .slider {
    height: 265px;
  }

  .ind h4 {
    font-size: 36px;
    padding-top: 26px;
  }

  .comp .slick-slide img {
    display: block;
    width: 58px;
    margin-left: 35px;
  }

  .ind .main-div {
    border-bottom: 1px solid #455A64;
    border-top: 1px solid #455A64;
    padding: 15px 0;
    margin-top: 29px;
  }

  .conn h3 {
    font-size: 48px !important;
  }

  .conn p {
    font-family: MADE TOMMY;
    font-size: 22px;
    font-weight: 400;
    margin: 20px 0 50px !important;
    text-transform: capitalize;
    color: #3B4045;
  }

  .conn button.btn {
    background: #0073E6;
    padding: 15px 10px;
    width: unset !important;
    margin: 10px 10px !important;
    font-size: 17px;
    font-weight: 500;
    border-radius: 9px;
    border: 2px solid #0073E6 !important;
    color: #fff;
    transition: .5s;
  }

  @keyframes horizontal-transform {
    0% {
      left: -10px;
    }

    50% {
      left: -10px;
    }

    75% {
      left: -10px;
    }

    100% {
      left: -10px;
    }
  }

  @keyframes horizontal-transform2 {
    0% {
      left: 10px;
    }

    50% {
      left: 10px;
    }

    75% {
      left: 10px;
    }

    100% {
      left: 10px;
    }
  }

  .pos-left {
    position: relative;
    left: 12px !important;
    animation: horizontal-transform 1s linear infinite;
    /* width: 100%; */
  }

  .s-all {
    background: #f0f0f0;
    padding: 80px 0 120px 0;
  }

  .bx {
    padding: 0 59px;
    position: relative;
    top: -15px;
  }

  .op .tx h3 {
    font-family: MADE TOMMY;
    font-size: 17px;
    font-weight: 400;
    margin: 10px 0px 9px 5px;
    text-transform: capitalize;
    color: #0d6efd;
    display: flex;
    align-items: center;
    background: #6bb1f980;
    padding: 10px;
    border-radius: 6px;
  }

  .op .bx h2 {
    font-family: MADE TOMMY;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    color: #05254D;
    line-height: 20px;
  }

  .section {
    position: relative;
    border-radius: 7px;
    margin: 0;
    width: 300px;
  }

  .section .pie {
    width: 300px !important;
    height: 300px !important;
  }

  .section svg {
    height: 300px !important;
    width: 300px !important;
  }

  .news .conn p {
    font-size: 16px;
  }

  .news .conn h3 {
    font-size: 50px !important;
  }

  .inp1 {
    font-size: 15px;
    height: 60px;
  }

  .inp button {
    font-size: 19px;
  }

  .news ul {
    display: block;
  }

  .news li {
    width: 100%;
  }

  .news button.btn {
    padding: 5px;
    width: 110px;
    margin: 0 !important;
    font-size: 16px;
  }

  footer .logo img {
    width: 28%;
  }

  .view1 h3 {
    font-size: 18px;
    line-height: 90.5%;
    margin-top: 40px;
  }

  .view2 {
    color: #05254D;
    font-family: MADE TOMMY;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 134%;
    letter-spacing: -0.16px;
    text-transform: capitalize;
    margin-top: 60px;
    position: relative;
    bottom: 20px;
  }

  footer .bg-img {
    padding: 0 20px 50px 20px;
  }

  footer .bg-img::before {
    background-size: cover;
  }

  .copi {
    font-size: 18px;
    margin-top: 10px;
  }

  .s-div .s2 {
    position: absolute;
    left: 50%;
    z-index: 1;
    width: 55%;
    bottom: 22px;
    transform: translate(-50%);
  }

  .s-all h2 {
    font-family: MADE TOMMY;
    font-size: 36px;
    font-weight: 400;
    text-transform: capitalize;
    color: #0073e6;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 100px;
  }

  html {
    overflow-x: hidden;
  }


  /* home page ended */

  /* learn page started */


  .learnbg .conn {
    margin-top: 60px;
  }

  li.nav-item {
    padding-bottom: 10px;
  }

  .tb-crd ul,
  ol {
    margin: 25px 0 30px 0;
  }

  .tb-crd h3 {
    font-size: 38px;
  }

  .tb-crd p {
    font-size: 19px;
    font-weight: 500;
  }

  .tb-crd button.btn {
    font-size: 16px;
    font-weight: 500;
  }

  .suri button.btndon {
    font-size: 16px !important;
    font-weight: 500;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 15px;
  }

  .tab-sli .slick-dots {
    bottom: -65px;
  }

  .suri {
    padding: 150px 0 !important;
  }

  .suri2 button.btndon {
    font-size: 16px !important;
    font-weight: 500;
    top: 80px !important;
  }

  .expect {
    padding: 1px 0px 90px;
  }

  .cer1 .txt {
    font-size: 22px;
    margin-top: 0;
  }

  .cer .box img {
    height: 90px !important;
  }

  .cer1 .box {
    padding: 30px 20px;
  }

  .bgnew {
    margin-top: 50px;
  }

  /* learn page ended */

  .news::before {
    width: 100px;
  }

  .links h6 {
    margin-bottom: 0;
  }

  header {
    padding-top: 40px;
  }

  .conn .d-flex {
    display: flex !important;
    text-align: center;
    /* justify-content: space-evenly; */
  }

  /* practice page started */


  .pad-day {
    padding: 90px 0px 40px !important;
  }

  .mock1 {
    padding-top: 60px;
  }

  .mock {
    padding: 50px 0px 90px;
  }

  .heigy {
    width: 100%;
    object-fit: contain;
    height: 370px;
  }

  /* practice page ended */

  /* mentorship page started  */

  .he-img .box img {
    height: 300px !important;
  }

  .mentees .coli1 {
    top: 30px;
  }

  .mentees h3 {
    line-height: 36px;
    font-size: 32px !important;
  }

  .conin1 {
    padding-left: 20px;
    padding-top: 100px;
  }

  .mentees  {
    font-size: 60px !important;
    line-height: 55px !important;
  }

  .mentees .coli3 {
    bottom: -55px;
  }

  .quota {
    width: 15%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .conn.the-shell {
    width: 100%;
  }

  .shell2 {
    left: 0;
    top: 0;
  }

  .shell3 {
    left: 0;
    top: 0;
  }

  section.tst0923 {
    padding: 110px 0 150px 0;
  }

  .mn {
    padding: 20px 0px 20px 0;
  }

  .mn::before {
    position: relative !important;
    height: 300px;
    width: 100%;
    display: block;
    bottom: 0;
    right: 0;
  }

  .conn9834 {
    text-align: center;
  }

  .conn9834 p {
    padding: 0 50px;
    font-size: 18px;
  }

  .more-exiting-oportunity .down-txt {
    top: 90px;
    left: 20px;
  }

  .more-exiting-oportunity h5 {
    font-size: 26px;
  }

  .more-exiting-oportunity img {
    width: 100%;
    height: 80%;
  }

  .more-exiting-oportunity h3 {
    font-size: 40px;
    font-weight: 500;
  }

  .more-exiting-oportunity p {
    font-size: 19px;
    margin: 20px 0 0px;
  }

  .more-exiting-oportunity .text-center {
    padding: 0 !important;
  }

  .cerformin {
    padding: 60px 0 50px !important;
  }

  .pad-bm {
    padding-bottom: 50px;
  }

  .tabbs .nav-tabs {
    margin-bottom: 35px;
  }

  .tags a {
    margin: 0 6px 15px 0;
  }

  .tab098 a {
    font-size: 15px;
  }

  .op .bx h2 {
    margin: 20px 0 15px;
    font-size: 24px;
    line-height: 30px;
  }

  .tab098 h2 {
    font-size: 21px;
  }

  .tab098 p {
    font-size: 15px;
  }

  .sess {
    margin-top: 40px;
  }

  .sess .sess1 {
    display: flex;
    padding: 30px 15px 20px 15px;
  }

  .sess .sess1 .li22 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px;
  }

  .sess .sess1 li button {
    width: 150px !important;
  }

  .tags {
    display: flex;
    align-items: stretch;
    flex-direction: row;
  }

  /* mentorship page ended  */

  /* compete page started */

  section.more-exiting-oportunity {
    padding: 50px 0 70px 0;
  }

  .mt-opbck {
    margin-top: 50px;
  }

  .jonny .jap2 {
    top: 25px;
    right: -150px !important;
  }

  .jonny .jap2 img {
    width: 60%;
  }

  /* compete page ended */


  /* job page done */

  /* pricing page started */

  .pricing .conn h3 {
    font-size: 32px !important;
    line-height: 30px;
    margin-bottom: 30px;
  }

/* .pricingTable .pricing-content li {} */

.pricingTable .pricing-content li {
    font-size: 15px;
}

  .membership {
    padding: 60px 0;
  }

  .pricingTable .price-value .currency {
    font-size: 35px;
    line-height: 44px;
  }

  .pricingTable .price-value .amount {
    font-size: 50px;
  }

  .pricingTable .price-value .duration {
    margin: 0 0 0 -3px;
  }

  .pricingTable .title h3 {
    font-size: 10px !important;
  }

  .faq-sec .accordion .accordion-item .collapsed {
    font-size: 17px;
  }

  #faq .accordion-body {
    font-size: 16px;
  }

  /* pricing page ended */

  /* employers page started */


  .main-employers h4 {
    font-size: 40px;
    line-height: 50px;
  }

  .main-employers::before {
    height: 60%;
  }

  .main-employers {
    padding: 70px 0 150px;
  }

  .main-employers p {
    font-size: 17px;
    padding: 0;
  }

  .main-employers img {
    width: 100%;
    height: 57%;
  }

  .main-We-simplify-your-hiring .bg-img {
    margin: 70px 0;
    padding: 20px 0 75px 0;
    background-size: 100% 85%;
  }

  .cerili .nav {
    align-items: center;
    padding-bottom: 80px;
  }

  .qut2 {
    top: -50px;
    width: 110px;
  }

  .thesolo .shell2 {
    left: 0;
  }

  .thesolo .shell3 {
    left: 0;
    top: 50px;
  }

  .qut-vid-1 {
    bottom: -95px;
    width: 100px;
  }

  /* employers page started */

  /* quizing page started */

  .japi-par {
    align-items: center;
    margin-bottom: 25px;
  }

  .japi-par h6 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .japii h3 {
    font-size: 15px;
  }

  .japi-par li img {
    height: 60px;
    width: 60px;
    margin-right: 50px;
  }

  .cardio .bgcar li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
  }

  .cardio .one,
  .cardio .two,
  .cardio .three,
  .cardio .four {
    width: 100%;
  }

  .cardio ul {
    display: block;
    padding: 14px 0px 14px 0;
    margin: 20px 0;
  }

  .cardio .three {
    justify-content: center !important;
  }

  .cardio .two div {
    text-align: center;
  }

  .pages2 {
    justify-content: center;
  }

  .pages1,
  .pages2 {
    text-align: center;
  }

  .lefty {
    left: 0px;
  }

  /* quizing page ended */


  /* partners page started */

  .partner .conn p {
    margin: 12px 0 40px 0;
  }

  /* partners page started */

  /* clients page started */

  section#clients_page_bnr_sec li {
    width: 50% !important;
    padding: 30px 0px 30px !important;
    justify-content: center !important;
  }

  #clients_page_bnr_sec h6 {
    font-size: 24px !important;
  }

  #clients_page_bnr_sec figure p {
    font-size: 18px !important;
    font-weight: 500 !important;
  }

  #clients_page_bnr_sec li:nth-child(2) {
    padding-left: 15px !important;
  }

  #clients_page_bnr_sec li:last-child {
    padding-left: 15px !important;
  }

  .tech_four_sec_box_main img {
    height: 160px;
  }

  .tech_page_text_main {
    padding: 25px 25px;
  }

  .tech_text_box_inner h6 {
    font-size: 20px;
  }

  .tech_text_box_inner h6 {
    font-size: 20px;
    padding-bottom: 10px;
  }

  /* clients page ended */

  /* about page started */


  .mission .bg::after {
    height: 315px;
    width: 250px;
    top: 0;
    right: 0;
    object-fit: cover;
  }

  .mission .bg {
    padding: 200px 0 20px 15px;
    height: 100%;
  }

  .leftig {
    left: 0px;
  }

  .mission .conn p {
    font-size: 17px !important;
  }

  .val {
    font-size: 16px;
  }

  .ind2 .box .conn h3 {
    font-size: 50px !important;
  }

  .boxiii li {
    width: 100%;
  }

  .boxiii {
    flex-direction: column;
    padding: 60px 0 70px 0;
  }

  .platform h1 {
    font-size: 26px;
    font-weight: 500;
  }

  /* about page ended */



  /* faq page done */

  /* term condition started */
  .terms .conn p {
    font-size: 18px;
    line-height: 34px;
    margin: 30px 0;
    font-weight: 400;
  }

  .terms .conn h3 {
    font-size: 32px;
  }

  .terms li {
    font-size: 18px;
    line-height: 30px;
    margin: 30px 0;
    font-weight: 400;
  }

  /* term condition ended */



  /* privacy policy done */



  /* career page started */

  .learnbg .jap2 {
    right: -140px !important;
    width: 80%;
  }

  .learnbg .jap-im-right {
    right: -250px !important;
    width: 80%;
  }

  .learnbg .jap2-im-right {
    right: -250px !important;
    width: 80%;
  }

  .s-div {
    height: 450px;
  }

  .perks-and-benefits .cont-box-txt {
    padding: 0px 10px 0;
    font-size: 18px;
  }

  .perks-and-benefits .pb-img {
    width: 55px;
    height: 55px;
  }

  .life-at-unstop-with-tabs {
    padding: 0 0 80px 0;
  }


  /* career page started */
}

@media only screen and (min-width: 520px) and (max-width: 767px) {

  /* home page started */
  .op h4 {
    font-size: 22px;
  }

  .op .p {
    font-size: 20px;
  }

  .op .box {
    margin: 60px 5px 0;
  }

  .s-div {
    height: 345px;
  }

  .mega {
    margin-top: 0;
    transition: 1s;
    z-index: 999;
    position: relative;
  }

  .cls {
    display: grid;
    padding: 14px 7px 9px 0;
    box-shadow: #107cc647 0px 2px 8px 0px;
    border-radius: 4px;
    margin-top: 0;
  }

  .cls .cl2,
  .cls .cl3,
  .cls .cl5 {
    width: unset;
  }

  ul.brr a {
    font-family: 'MADE TOMMY';
    font-size: 15px;
  }

  ul.brr {
    border-right: 1px solid #8b8b8b;
    padding-left: 6px;
    margin: 13px 0;
  }

  .cls .cl1,
  .cls .cl4 {
    width: unset;
  }

  .mega {
    left: -660px;
  }

  .megaShow {
    margin-top: unset !important;
    left: unset !important;
  }

  .navbar-light .navbar-toggler {
    display: none;
  }

  header .logo {
    max-width: 25% !important;
    height: 59px;
  }

  header .width {
    width: unset;
  }

  a.navbar-brand {
    position: absolute;
    top: 20px;
    right: 15px;
  }

  .navbar-light .navbar-brand {
    margin: 0;
    padding: 4px 11px 4px 11px;
    font-size: 15px;
  }

  button.btn {
    padding: 5px;
    width: 102px;
    margin: 0 7px;
    font-size: 13px;
  }

  header .d-flex {
    justify-content: center;
    position: relative;
    z-index: 9;
  }

  .banner h6 {
    font-size: 15px;
    letter-spacing: 1px
  }

  .banner h5 {
    font-size: 36px;
    font-weight: 400;
    line-height: 41px;
    margin: 17px 0 17px 0;
  }

  .bn-slider1 li.slick-active button {
    width: 85px !important;
  }

  .bckkk::before {
    background-position: top;
    height: 100%;
    animation: unset;
    content: unset !important;
  }

  .sl {
    margin-top: 15px;
    object-fit: cover;
  }

  .banner .slider {
    height: 265px;
  }

  .ind h4 {
    font-size: 28px;
    padding-top: 26px;
  }

  .comp .slick-slide img {
    display: block;
    width: 58px;
    margin-left: 35px;
  }

  .ind .main-div {
    border-bottom: 1px solid #455A64;
    border-top: 1px solid #455A64;
    padding: 15px 0;
    margin-top: 29px;
  }

  .conn h3 {
    font-size: 30px;
  }

  .conn p {
    font-family: MADE TOMMY;
    font-size: 18px;
    font-weight: 400;
    margin: 16px 0 16px;
    text-transform: capitalize;
    color: #3B4045;
  }

  .conn button.btn {
    background: #0073E6;
    padding: 7px;
    width: 230px;
    margin: 5px 0 !important;
    font-size: 14px;
    font-weight: 500;
    border-radius: 9px;
    border: 2px solid #0073E6 !important;
    color: #fff;
    transition: .5s;
  }

  @keyframes horizontal-transform {
    0% {
      left: -10px;
    }

    50% {
      left: -10px;
    }

    75% {
      left: -10px;
    }

    100% {
      left: -10px;
    }
  }

  @keyframes horizontal-transform2 {
    0% {
      left: 10px;
    }

    50% {
      left: 10px;
    }

    75% {
      left: 10px;
    }

    100% {
      left: 10px;
    }
  }

  .pos-left {
    position: relative;
    left: 12px !important;
    animation: horizontal-transform 1s linear infinite;
    /* width: 100%; */
  }

  .s-all {
    background: #f0f0f0;
    padding: 55px 0 130px 0;
  }

  .bx {
    padding: 0 29px;
    position: relative;
    top: -15px;
  }

  .op .tx h3 {
    font-family: MADE TOMMY;
    font-size: 12px;
    font-weight: 400;
    margin: 0px 20px 9px 0;
    text-transform: capitalize;
    color: #0d6efd;
    display: flex;
    align-items: center;
    background: #6bb1f980;
    padding: 4px 10px 4px 4px;
    border-radius: 6px;
  }

  .op .bx h2 {
    font-family: MADE TOMMY;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    color: #05254D;
    line-height: 20px;
  }

  .section {
    position: relative;
    border-radius: 7px;
    margin: 20px;
    width: 300px;
  }

  .section .pie {
    width: 300px !important;
    height: 300px !important;
  }

  .section svg {
    height: 300px !important;
    width: 300px !important;
  }

  .news .conn p {
    font-size: 15px;
  }

  .news .conn h3 {
    font-size: 34px;
  }

  .inp1 {
    font-size: 13px;
    height: 51px;
  }

  .inp button {
    font-size: 19px;
  }

  .news ul {
    display: block;
  }

  .news li {
    width: 100%;
  }

  .news button.btn {
    padding: 5px;
    width: 110px;
    margin: 0 !important;
    font-size: 16px;
  }

  footer .logo img {
    width: 35%;
  }

  .view1 h3 {
    font-size: 20px;
    line-height: 90.5%;
  }

  .view2 {
    color: #05254D;
    font-family: MADE TOMMY;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 134%;
    letter-spacing: -0.16px;
    text-transform: capitalize;
    margin-top: 70px;
    position: relative;
    bottom: 20px;
  }

  footer .bg-img {
    padding: 0 20px 50px 20px;
  }

  footer .bg-img::before {
    background-size: cover;
  }

  .copi {
    font-size: 17px;
    margin-top: 10px;
    font-weight: 400;
  }

  .s-div .s2 {
    position: absolute;
    left: 50%;
    z-index: 1;
    width: 55%;
    bottom: 22px;
    transform: translate(-50%);
  }

  .s-all h2 {
    font-family: MADE TOMMY;
    font-size: 32px;
    font-weight: 400;
    text-transform: capitalize;
    color: #0073e6;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 35px;
  }

  html {
    overflow-x: hidden;
  }


  /* home page ended */

  /* learn page started */


  .learnbg .conn {
    margin-top: 60px;
  }

  li.nav-item {
    padding-bottom: 10px;
  }

  .tb-crd ul,
  ol {
    margin: 25px 0 35px 0;
  }

  .tb-crd h3 {
    font-size: 28px;
  }

  .tb-crd p {
    font-size: 17px;
    font-weight: 500;
  }

  .tb-crd button.btn {
    font-size: 16px;
    font-weight: 500;
  }

  .suri button.btndon {
    font-size: 16px !important;
    font-weight: 500;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 15px;
  }

  .tab-sli .slick-dots {
    bottom: -65px;
  }

  .suri {
    padding: 150px 0 !important;
  }

  .suri2 button.btndon {
    font-size: 16px !important;
    font-weight: 500;
    top: 80px !important;
  }

  .expect {
    padding: 1px 0px 90px;
  }

  .cer1 .txt {
    font-size: 17px;
    margin-top: 0;
  }

  .cer .box img {
    height: 70px !important;
  }

  .cer1 .box {
    padding: 35px 25px;
  }

  .bgnew {
    margin-top: 50px;
  }

  /* learn page ended */

  .news::before {
    width: 100px;
  }

  .links h6 {
    margin-bottom: 0;
  }

  header {
    padding-top: 15px;
  }

  .conn .d-flex {
    display: block !important;
    text-align: center;
  }

  /* practice page started */


  .pad-day {
    padding: 90px 0px 40px !important;
  }

  .mock1 {
    padding-top: 60px;
  }

  .mock {
    padding: 50px 0px 90px;
  }

  .heigy {
    width: 100%;
  }

  /* practice page ended */

  /* mentorship page started  */

  .he-img .box img {
    height: 300px !important;
  }

  .mentees .coli1 {
    top: -50px;
  }

  .mentees h3 {
    line-height: 35px;
  }

  .conin1 {
    padding-left: 10px;
    padding-top: 30px;
  }

  .mentees .theyellow {
    font-size: 60px !important;
    line-height: 70px !important;
  }

  .mentees .coli3 {
    bottom: -55px;
  }

  .quota {
    width: 20%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .conn.the-shell {
    width: 100%;
  }

  .shell2 {
    left: 0;
    top: 0;
  }

  .shell3 {
    left: 0;
    top: 0;
  }

  section.tst0923 {
    padding: 110px 0 150px 0;
  }

  .mn {
    padding: 20px 0px 20px 0;
  }

  .mn::before {
    position: relative !important;
    height: 250px;
    width: 100%;
    display: block;
    bottom: 0;
    right: 0;
  }

  .conn9834 {
    text-align: center;
  }

  .conn9834 p {
    padding: 0 1px;
  }

  .more-exiting-oportunity .down-txt {
    top: 60px;
    left: 20px;
  }

  .more-exiting-oportunity h5 {
    font-size: 16px;
  }

  .more-exiting-oportunity img {
    width: 100%;
    height: 190px;
  }

  .more-exiting-oportunity h3 {
    font-size: 35px;
    font-weight: 500;
  }

  .more-exiting-oportunity p {
    font-size: 17px;
    margin: 30px 0 0px;
  }

  .more-exiting-oportunity .text-center {
    padding: 0 !important;
  }

  .cerformin {
    padding: 60px 0 50px !important;
  }

  .pad-bm {
    padding-bottom: 50px;
  }

  .tabbs .nav-tabs {
    margin-bottom: 25px;
  }

  .tags a {
    margin: 0 6px 15px 0;
  }

  .tab098 a {
    font-size: 14px;
  }

  .op .bx h2 {
    margin: 10px 0 10px;
    font-size: 18px;
    line-height: 29px;
  }

  .tab098 h2 {
    font-size: 21px;
  }

  .tab098 p {
    font-size: 15px;
  }

  .sess {
    margin-top: 40px;
  }

  .sess .sess1 {
    display: block;
    padding: 30px 15px 20px 15px;
  }

  .sess .sess1 .li22 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }

  .sess .sess1 li button {
    width: 150px !important;
  }

  .tags {
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }

  /* mentorship page ended  */

  /* compete page started */

  section.more-exiting-oportunity {
    padding: 50px 0 70px 0;
  }

  .mt-opbck {
    margin-top: 50px;
  }

  .jonny .jap2 {
    top: 25px;
    right: -105px;
  }

  .jonny .jap2 img {
    width: 70%;
  }

  /* compete page ended */


  /* job page done */

  /* pricing page started */

  .pricing .conn h3 {
    font-size: 40px !important;
    line-height: 45px;
    margin-bottom: 30px;
  }

  .membership {
    padding: 60px 0;
  }

  .pricingTable .price-value .currency {
    font-size: 35px;
    line-height: 44px;
  }

  .pricingTable .price-value .amount {
    font-size: 50px;
  }

  .pricingTable .price-value .duration {
    margin: 0 0 0 -3px;
  }

  .pricingTable .title h3 {
    font-size: 10px !important;
  }

  .faq-sec .accordion .accordion-item .collapsed {
    font-size: 16px;
  }

  #faq .accordion-body {
    font-size: 15px;
  }

  /* pricing page ended */

  /* employers page started */


  .main-employers h4 {
    font-size: 30px;
    line-height: 40px;
  }

  .main-employers::before {
    height: 60%;
  }

  .main-employers {
    padding: 70px 0;
  }

  .main-employers p {
    font-size: 16px;
    padding: 0 25px;
  }

  .main-employers img {
    width: 100%;
    object-fit: cover;
    height: 44%;
  }

  .main-We-simplify-your-hiring .bg-img {
    margin: 70px 0;
    padding: 40px 0 30px 0;
  }

  .cerili .nav {
    align-items: center;
    padding-bottom: 80px;
  }

  .qut2 {
    top: -50px;
    width: 110px;
  }

  .thesolo .shell2 {
    left: 0;
  }

  .thesolo .shell3 {
    left: 0;
    top: 50px;
  }

  .qut-vid-1 {
    bottom: -95px;
    width: 100px;
  }

  /* employers page started */

  /* quizing page started */

  .japi-par {
    align-items: center;
    margin-bottom: 25px;
  }

  .japi-par h6 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .japii h3 {
    font-size: 16px;
  }

  .japi-par li img {
    height: 60px;
    width: 60px;
  }

  .cardio .bgcar li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
  }

  .cardio .one,
  .cardio .two,
  .cardio .three,
  .cardio .four {
    width: 100%;
  }

  .cardio ul {
    display: block;
    padding: 14px 0px 14px 0;
    margin: 20px 0;
  }

  .cardio .three {
    justify-content: center !important;
  }

  .cardio .two div {
    text-align: center;
  }

  .pages2 {
    justify-content: center;
  }

  .pages1,
  .pages2 {
    text-align: center;
  }

  .lefty {
    left: 0px;
  }

  /* quizing page ended */


  /* partners page started */

  .partner .conn p {
    margin: 12px 0 40px 0;
  }

  /* partners page started */

  /* clients page started */

  section#clients_page_bnr_sec li {
    width: 50% !important;
    padding: 15px 0px 15px !important;
  }

  #clients_page_bnr_sec h6 {
    font-size: 18px !important;
  }

  #clients_page_bnr_sec figure p {
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  #clients_page_bnr_sec li:nth-child(2) {
    padding-left: 10px !important;
  }

  #clients_page_bnr_sec li:last-child {
    padding-left: 10px !important;
  }

  .tech_four_sec_box_main img {
    height: 160px;
  }

  .tech_page_text_main {
    padding: 25px 25px;
  }

  .tech_text_box_inner h6 {
    font-size: 20px;
  }

  .tech_text_box_inner h6 {
    font-size: 20px;
    padding-bottom: 10px;
  }

  /* clients page ended */

  /* about page started */


  .mission .bg::after {
    height: 250px;
    width: 190px;
    top: 0;
    right: 0;
    object-fit: cover;
  }

  .mission .bg {
    padding: 200px 0 20px 15px;
    height: 100%;
  }

  .leftig {
    left: 0px;
  }

  .mission .conn p {
    font-size: 15px !important;
  }

  .val {
    font-size: 12px;
  }

  .ind2 .box .conn h3 {
    font-size: 32px;
  }

  .boxiii li {
    width: 100%;
  }

  .boxiii {
    flex-direction: column;
  }

  .platform h1 {
    font-size: 24px;
  }

  /* about page ended */



  /* faq page done */

  /* term condition started */
  .terms .conn p {
    font-size: 15px;
    line-height: 30px;
    margin: 30px 0;
  }

  .terms .conn h3 {
    font-size: 32px;
  }

  .terms li {
    font-size: 16px;
    line-height: 30px;
    margin: 30px 0;
  }

  /* term condition ended */



  /* privacy policy done */



  /* career page started */

  .learnbg .jap2 {
    right: -40px;
  }

  .perks-and-benefits .cont-box-txt {
    padding: 0 25px 0;
  }

  .perks-and-benefits .pb-img {
    width: 70px;
    height: 70px;
  }

  .life-at-unstop-with-tabs {
    padding: 0 0 80px 0;
  }


  /* career page started */
}

@media only screen and (min-width: 300px) and (max-width: 519px) {


  /* home page started */
  .pricingTable .pricing-content li {
    font-size: 15px;
  }

  .life-at-unstop-with-tabs .accordion .accordion-item button {
    font-size: 16px;
    padding: 15px 10px;
  }

  .ele p {
    font-size: 18px;
  }

  .view1 h2 {
    font-size: 17px;
    margin: 30px 0px 25px 0;
  }

  .cli_sld_img_main img {
    width: 22%;
    margin-right: 15px;
  }

  .cli_sld_img_main h5 {
    font-size: 24px;
  }

  .cli_sld_img_main p {
    font-size: 18px !important;
  }

  .client_sldr_text_main_box {
    padding: 30px 30px 50px 20px;
  }

  .view1 .siri {
    width: 23px;
  }

  .view1 a {
    font-size: 17.017px;
  }

  .view1 .timing {
    font-size: 16px;
  }

  .accordion-item:first-of-type .accordion-button {
    font-size: 17px;
  }

  .mega {
    margin-top: 0;
    transition: 1s;
    z-index: 999;
    position: relative;
  }

  .cls {
    display: grid;
    padding: 14px 7px 9px 0;
    box-shadow: #107cc647 0px 2px 8px 0px;
    border-radius: 4px;
    margin-top: 0;
  }

  .cls .cl2,
  .cls .cl3,
  .cls .cl5 {
    width: unset;
  }

  ul.brr a {
    font-family: 'MADE TOMMY';
    font-size: 15px;
  }

  ul.brr {
    border-right: 1px solid #8b8b8b;
    padding-left: 6px;
    margin: 13px 0;
  }

  .cls .cl1,
  .cls .cl4 {
    width: unset;
  }

  .mega {
    left: -460px;
  }

  .megaShow {
    margin-top: unset !important;
    left: unset !important;
  }

  .navbar-light .navbar-toggler {
    display: none;
  }

  header .logo {
    max-width: 120px !important;
    height: 56px;
  }

  header .width {
    width: unset;
  }

  a.navbar-brand {
    position: absolute;
    top: 20px;
    right: 15px;
  }

  .navbar-light .navbar-brand {
    margin: 0;
    padding: 4px 11px 4px 11px;
    font-size: 15px;
  }

  button.btn {
    padding: 5px;
    width: 102px;
    margin: 0 7px;
    font-size: 13px;
  }

  header .d-flex {
    justify-content: center;
    position: relative;
    z-index: 9;
  }

  .banner h6 {
    font-size: 14px;
    letter-spacing: 1px
  }

  .banner h5 {
    font-size: 35px;
    font-weight: 400;
    line-height: 41px;
    margin: 13px 0 17px 0;
  }

  .bn-slider1 li.slick-active button {
    width: 85px !important;
  }

  .bckkk::before {
    background-position: top;
    height: 100%;
    animation: unset;
    content: unset !important;
  }

  .sl {
    margin-top: 15px;
    object-fit: cover;
  }

  .banner .slider {
    height: 265px;
  }

  .ind h4 {
    font-size: 28px;
    padding-top: 26px;
  }

  .comp .slick-slide img {
    display: block;
    width: 58px;
    margin-left: 35px;
  }

  .ind .main-div {
    border-bottom: 1px solid #455A64;
    border-top: 1px solid #455A64;
    padding: 15px 0;
    margin-top: 29px;
  }

  .conn h3 {
    font-size: 33px;
  }

  .conn p {
    font-family: MADE TOMMY;
    font-size: 15px;
    font-weight: 400;
    margin: 16px 0 16px;
    text-transform: capitalize;
    color: #3B4045;
  }

  .conn button.btn {
    background: #0073E6;
    padding: 7px;
    width: 230px;
    margin: 5px 0 !important;
    font-size: 14px;
    font-weight: 500;
    border-radius: 9px;
    border: 2px solid #0073E6 !important;
    color: #fff;
    transition: .5s;
  }

  @keyframes horizontal-transform {
    0% {
      left: -10px;
    }

    50% {
      left: -10px;
    }

    75% {
      left: -10px;
    }

    100% {
      left: -10px;
    }
  }

  @keyframes horizontal-transform2 {
    0% {
      left: 10px;
    }

    50% {
      left: 10px;
    }

    75% {
      left: 10px;
    }

    100% {
      left: 10px;
    }
  }

  .pos-left {
    position: relative;
    left: 12px !important;
    animation: horizontal-transform 1s linear infinite;
    /* width: 100%; */
  }

  .s-all {
    background: #f0f0f0;
    padding: 55px 0 130px 0;
  }

  .bx {
    padding: 0 29px;
    position: relative;
    top: -15px;
  }

  .op .tx h3 {
    font-family: MADE TOMMY;
    font-size: 12px;
    font-weight: 400;
    margin: 0px 20px 9px 0;
    text-transform: capitalize;
    color: #0d6efd;
    display: flex;
    align-items: center;
    background: #6bb1f980;
    padding: 4px 10px 4px 4px;
    border-radius: 6px;
  }

  .op .bx h2 {
    font-family: MADE TOMMY;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    color: #05254D;
    line-height: 20px;
  }

  .section {
    position: relative;
    border-radius: 7px;
    margin: 20px;
    width: 300px;
  }

  .section .pie {
    width: 300px !important;
    height: 300px !important;
  }

  .section svg {
    height: 300px !important;
    width: 300px !important;
  }

  .news .conn p {
    font-size: 17px;
  }

  .news .conn h3 {
    font-size: 31px;
  }

  .inp1 {
    font-size: 15px;
    height: 51px;
  }

  .inp button {
    font-size: 19px;
  }

  .news ul {
    display: block;
  }

  .news li {
    width: 100%;
  }

  .news button.btn {
    padding: 5px;
    width: 110px;
    margin: 0 !important;
    font-size: 16px;
  }

  footer .logo img {
    width: 50%;
  }

  .view1 h3 {
    font-size: 16px;
    line-height: 90.5%;
  }

  .view2 {
    color: #05254D;
    font-family: MADE TOMMY;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 134%;
    letter-spacing: -0.16px;
    text-transform: capitalize;
    margin-top: 70px;
    position: relative;
    bottom: 20px;
  }

  footer .bg-img {
    padding: 0 20px 50px 20px;
  }

  footer .bg-img::before {
    background-size: cover;
  }

  .copi {
    font-size: 14px;
    margin-top: 10px;
    font-weight: 400;
  }

  .s-div .s2 {
    position: absolute;
    left: 50%;
    z-index: 1;
    width: 55%;
    bottom: 22px;
    transform: translate(-50%);
  }

  .s-all h2 {
    font-family: MADE TOMMY;
    font-size: 28px;
    font-weight: 400;
    text-transform: capitalize;
    color: #0073e6;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 35px;
  }

  html {
    overflow-x: hidden;
  }


  /* home page ended */

  /* learn page started */


  .learnbg .conn {
    margin-top: 60px;
  }

  li.nav-item {
    padding-bottom: 1px;
  }

  .tb-crd ul,
  ol {
    margin: 25px 0 35px 0;
  }

  .tb-crd h3 {
    font-size: 28px;
  }

  .tb-crd p {
    font-size: 17px;
    font-weight: 500;
  }

  .tb-crd button.btn {
    font-size: 16px;
    font-weight: 500;
  }

  .suri button.btndon {
    font-size: 16px !important;
    font-weight: 500;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 15px;
  }

  .tab-sli .slick-dots {
    bottom: -65px;
  }

  .suri {
    padding: 150px 0 !important;
  }

  .suri2 button.btndon {
    font-size: 16px !important;
    font-weight: 500;
    top: 80px !important;
  }

  .expect {
    padding: 1px 0px 90px;
  }

  .cer1 .txt {
    font-size: 17px;
    margin-top: 0;
  }

  .cer .box img {
    height: 70px !important;
  }

  .cer1 .box {
    padding: 35px 25px;
  }

  .bgnew {
    margin-top: 50px;
  }

  /* learn page ended */

  .news::before {
    width: 100px;
  }

  .links h6 {
    margin-bottom: 0;
  }

  header {
    padding-top: 15px;
  }

  .conn .d-flex {
    display: block !important;
    text-align: center;
  }

  /* practice page started */


  .pad-day {
    padding: 90px 0px 40px !important;
  }

  .mock1 {
    padding-top: 60px;
  }

  .mock {
    padding: 50px 0px 90px;
  }

  .heigy {
    width: 100%;
  }

  /* practice page ended */

  /* mentorship page started  */

  .he-img .box img {
    height: 300px !important;
  }

  .mentees .coli1 {
    top: -50px;
  }

  .mentees h3 {
    line-height: 35px;
  }

  .conin1 {
    padding-left: 10px;
    padding-top: 30px;
  }

  .mentees .theyellow {
    font-size: 60px !important;
    line-height: 70px !important;
  }

  .mentees .coli3 {
    bottom: -55px;
  }

  .quota {
    width: 20%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .conn.the-shell {
    width: 100%;
  }

  .shell2 {
    left: 0;
    top: 0;
  }

  .shell3 {
    left: 0;
    top: 0;
  }

  section.tst0923 {
    padding: 50px 0 150px 0;
  }

  .mn {
    padding: 20px 0px 20px 0;
  }

  .mn::before {
    position: relative !important;
    height: 250px;
    width: 100%;
    display: block;
    bottom: 0;
    right: 0;
  }

  .conn9834 {
    text-align: center;
  }

  .conn9834 p {
    padding: 0 1px;
  }

  .more-exiting-oportunity .down-txt {
    top: 60px;
    left: 20px;
  }

  .more-exiting-oportunity h5 {
    font-size: 16px;
  }

  .more-exiting-oportunity img {
    width: 100%;
    height: 190px;
  }

  .more-exiting-oportunity h3 {
    font-size: 35px;
    font-weight: 500;
  }

  .more-exiting-oportunity p {
    font-size: 17px;
    margin: 30px 0 0px;
  }

  .more-exiting-oportunity .text-center {
    padding: 0 !important;
  }

  .cerformin {
    padding: 60px 0 50px !important;
  }

  .pad-bm {
    padding-bottom: 50px;
  }

  .tabbs .nav-tabs {
    margin-bottom: 25px;
  }

  .tags a {
    margin: 0 6px 15px 0;
  }

  .tab098 a {
    font-size: 14px;
  }

  .op .bx h2 {
    margin: 10px 0 10px;
    font-size: 18px;
    line-height: 29px;
  }

  .tab098 h2 {
    font-size: 21px;
  }

  .tab098 p {
    font-size: 14px;
}
.sess .sess1 li h2 {
  font-size: 16px;
}
.conn button.btn {
  font-size: 13px;
}
.sess .sess1 .li22 {
  padding-top: 10px !important;
}
  .sess {
    margin-top: 40px;
  }

  .sess .sess1 {
    display: block;
    padding: 30px 15px 20px 15px;
  }

  .sess .sess1 .li22 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }

  .sess .sess1 li button {
    width: 150px !important;
  }

  .tags {
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }

  /* mentorship page ended  */

  /* compete page started */

  section.more-exiting-oportunity {
    padding: 50px 0 70px 0;
  }

  .mt-opbck {
    margin-top: 50px;
  }

  .jonny .jap2 {
    top: 25px;
    right: -105px;
  }

  .jonny .jap2 img {
    width: 70%;
  }

  /* compete page ended */


  /* job page done */

  /* pricing page started */

  .pricing .conn h3 {
    font-size: 40px !important;
    line-height: 45px;
    margin-bottom: 30px;
  }

  .membership {
    padding: 60px 0;
  }

  .pricingTable .price-value .currency {
    font-size: 35px;
    line-height: 44px;
  }

  .pricingTable .price-value .amount {
    font-size: 50px;
  }

  .pricingTable .price-value .duration {
    margin: 0 0 0 -3px;
  }

  .pricingTable .title h3 {
    font-size: 10px !important;
  }

  .faq-sec .accordion .accordion-item .collapsed {
    font-size: 16px;
  }

  #faq .accordion-body {
    font-size: 15px;
  }

  /* pricing page ended */

  /* employers page started */


  .main-employers h4 {
    font-size: 30px;
    line-height: 40px;
  }

  .main-employers::before {
    height: 60%;
  }

  .main-employers {
    padding: 70px 0;
  }

  .main-employers p {
    font-size: 16px;
    padding: 0 25px;
  }

  .main-employers img {
    width: 100%;
    object-fit: cover;
    height: 44%;
  }

  .main-We-simplify-your-hiring .bg-img {
    margin: 70px 0;
    padding: 40px 0 30px 0;
  }

  .cerili .nav {
    align-items: center;
    padding-bottom: 80px;
  }

  .qut2 {
    top: -50px;
    width: 110px;
  }

  .thesolo .shell2 {
    left: 0;
  }

  .thesolo .shell3 {
    left: 0;
    top: 50px;
  }

  .qut-vid-1 {
    bottom: -95px;
    width: 100px;
  }

  /* employers page started */

  /* quizing page started */

  .japi-par {
    align-items: center;
    margin-bottom: 25px;
  }

  .japi-par h6 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .japii h3 {
    font-size: 16px;
  }

  .japi-par li img {
    height: 60px;
    width: 60px;
  }

  .cardio .bgcar li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
  }

  .cardio .one,
  .cardio .two,
  .cardio .three,
  .cardio .four {
    width: 100%;
  }

  .cardio ul {
    display: block;
    padding: 14px 0px 14px 0;
    margin: 20px 0;
  }

  .cardio .three {
    justify-content: center !important;
  }

  .cardio .two div {
    text-align: center;
  }

  .pages2 {
    justify-content: center;
  }

  .pages1,
  .pages2 {
    text-align: center;
  }

  .lefty {
    left: 0px;
  }

  /* quizing page ended */


  /* partners page started */

  .partner .conn p {
    margin: 12px 0 40px 0;
  }

  /* partners page started */

  /* clients page started */

  section#clients_page_bnr_sec li {
    width: 50% !important;
    padding: 15px 0px 15px !important;
  }

  #clients_page_bnr_sec h6 {
    font-size: 18px !important;
  }

  #clients_page_bnr_sec figure p {
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  #clients_page_bnr_sec li:nth-child(2) {
    padding-left: 10px !important;
  }

  #clients_page_bnr_sec li:last-child {
    padding-left: 10px !important;
  }

  .tech_four_sec_box_main img {
    height: 160px;
  }

  .tech_page_text_main {
    padding: 25px 25px;
  }

  .tech_text_box_inner h6 {
    font-size: 20px;
  }

  .tech_text_box_inner h6 {
    font-size: 20px;
    padding-bottom: 10px;
  }

  /* clients page ended */

  /* about page started */


  .mission .bg::after {
    height: 250px;
    width: 190px;
    top: 0;
    right: 0;
    object-fit: cover;
  }

  .mission .bg {
    padding: 200px 0 20px 15px;
    height: 100%;
  }

  .leftig {
    left: 0px;
  }

  .mission .conn p {
    font-size: 16px !important;
    width: 75%;
  }

  .val {
    font-size: 12px;
  }

  .ind2 .box .conn h3 {
    font-size: 32px;
  }

  .boxiii li {
    width: 100%;
  }

  .boxiii {
    flex-direction: column;
  }

  .platform h1 {
    font-size: 24px;
  }

  /* about page ended */



  /* faq page done */

  /* term condition started */
  .terms .conn p {
    font-size: 15px;
    line-height: 30px;
    margin: 30px 0;
  }

  .terms .conn h3 {
    font-size: 32px;
  }

  .terms li {
    font-size: 16px;
    line-height: 30px;
    margin: 30px 0;
  }

  /* term condition ended */



  /* privacy policy done */



  /* career page started */

  .learnbg .jap2 {
    right: -50px;
    width: 250px;
  }

  .perks-and-benefits .cont-box-txt {
    padding: 0 25px 0;
  }

  .perks-and-benefits .pb-img {
    width: 70px;
    height: 70px;
  }

  .life-at-unstop-with-tabs {
    padding: 0 0 80px 0;
  }

  header::before {
    height: 7px;
  }

  .bckkk45534 {
    max-width: 83%;
  }

  .slideiu {
    display: flex;
    margin-bottom: unset;
    margin-top: unset;
    flex-flow: wrap;
  }

  li.lili {
    width: 43%;
    height: 100%;
  }

  .slideiu li:nth-child(even) {
    bottom: unset;
  }

  .styleWidth {
    width: 65px !important;
    margin: 0 11px;
  }

  .marquee {
    height: 70px;
    position: relative;
    overflow-x: hidden;
    line-height: 36px;
    overflow-y: hidden;
  }

  .op .slick-dots {
    bottom: -65px;
  }

  .op {
    background: #f0f0f0;
    padding: 0 0 57px 0;
    position: relative;
  }

  .pos-right {
    position: relative;
    left: unset;
    animation: horizontal-transform2 1s linear infinite;
  }

  .pos-left {
    position: relative;
    left: 0px !important;
    animation: horizontal-transform 1s linear infinite;
    /* width: 100%; */
  }

  .pos-right {
    position: unset;
    left: unset;
    animation: horizontal-transform2 1s linear infinite;
  }

  .s-div {
    position: relative;
    height: 254px;
    display: flex;
    align-items: center;
  }

  .section {
    position: relative;
    border-radius: 7px;
    margin: 0;
    width: unset;
    left: 0;
    display: flex;
    justify-content: center;
  }

  .jonny .jap2 {
    right: -50px !important;
    width: 250px !important;
  }
  .learnbg .jap2 .imgfly {
    top: -23px !important;
}

  /* career page started */

}

.tabbs .nav-tabs .nav-link {
  margin: 4px 6px;
}

.hired img#hired-wi {
  width: 95% !important;
}

.tabbs .box .inner-head-b h3 {
  font-size: 30px;
  margin-top: 15px;
}

.tabbs .box .inner-head-b p {
  font-size: 16px;
  margin: 10px 0 16px;
}

.tabbs {
  left: 0 !important;
}

.learnbg .jap2 img {
  width: 360px !important;
  margin-bottom: 55px;
  height: 400px;
  object-fit: contain;
  position: relative;
  top: -30px;
}

.jonny .jap2 {
  right: -100px;
}

.tabbs .box .inner-head-b h3 {
  font-size: 30px;
  margin-top: 15px;
}

.tabbs .box .inner-head-b p {
  font-size: 16px;
  margin: 10px 0 16px;
}

.tabbs {
  left: 0 !important;
}

.learnbg .jap2 img {
  width: 360px !important;
  margin-bottom: 55px;
  height: 400px;
  object-fit: contain;
  position: relative;
  top: -30px;
}

.jonny .jap2 {
  right: -100px;
}
.con-sec.op h2 {
  cursor: pointer;
}